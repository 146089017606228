import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Alert, Box } from '@mui/material';
import { HELPER_TEXT, RULE_PATTERN_VALUE } from '../../constant';



const RejectedReasonDialog = ({open, onClose, options, onSubmit}) => {
  const [rejectedReason, setRejectedReason] = useState('');
  const [checkboxValues, setCheckboxValues] = useState({});
  const [errorText, setErrorText] = useState(null);

  const handleRadioChange = (event) => {
    setErrorText(null);
  };

  const handleCheckboxChange = (event) => {
    setErrorText(null);
    setCheckboxValues({
      ...checkboxValues,
      [event.target.name]: event.target.checked,
    });
  };

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    // Handle form submission here

    if (!rejectedReason.trim()) {
      setErrorText('Please provide a rejected reason.');
      return;
    }

    var obj = {};
    for(var key in checkboxValues)
    {
      if(checkboxValues[key] === true) obj[key] = true;
    }

    if(rejectedReason) obj.rejectedReason = rejectedReason;
    
    onSubmit(obj);

    handleClose();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Reject the registeration</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please select the rejected fields
          </DialogContentText>

          <Box sx={{paddingLeft:"0px"}}>
          {
            options.map((option) => (
              <FormControlLabel
                key={option.id}
                sx = {{width:"100%"}}
                control={
                  <Checkbox
                    checked={checkboxValues[option.id] || false}
                    onChange={handleCheckboxChange}
                    name={option.id}
                  />
                }
                label={option.label}
              />
            ))
          }
          </Box>
            <Box sx={{paddingLeft:"0px"}}>
            {
            <TextField
              label="Rejected Reason"
              fullWidth
              required
              value={rejectedReason}
              onChange={(e) => setRejectedReason(e.target.value)}
              margin="normal"
              helperText={HELPER_TEXT.MAX_256}
							inputProps={{ maxLength: 256 }}
              multiline
              sx={{width:{xl:500, md:400, sm:"auto", xs:"auto"}}}
              minRows={4}
              maxRows={4}
            />
            }
            </Box>
          {
            (errorText)
            ?
            <Alert sx = {{marginTop:1}} variant="filled" severity="error">{errorText}</Alert>
            :
            <></>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RejectedReasonDialog;
