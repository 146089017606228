import React from 'react'
import { Layout } from '../layouts/dashboard/layout'
import { Box, Container, Stack, Typography} from '@mui/material';
import { getUserInfo } from '../guards/auth-guard';
import ProfilePreview from '../sections/profile/ProfilePreview';

const Profile = () => {

  const user = getUserInfo();


  return (
    <Layout user = {user} >
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 1
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={4}
            >
              <Stack spacing={1} sx={{width:"100%"}} >
                <Typography variant="h5">
                  Profile
                </Typography>
              </Stack>
            </Stack>
            <ProfilePreview/>
            
          </Stack>
        </Container>
      </Box>
    </Layout>
  )
}

export default Profile