import React, { useEffect, useState } from 'react'
import Dialog from '../../components/Dialog';
import Backdrop from '../../components/Backdrop';
import Snackbar from '../../components/Snackbar';
import Info from '../../components/Info';
import { Box, CircularProgress, Unstable_Grid2 as Grid} from '@mui/material';
import { ERROR_STR, dialogProps, infoProps, snackbarProps } from '../../constant';
import { API_STFI } from '../../api/stfi';
import { CountWidget } from '../../components/CountWidget';
import { useNavigate } from 'react-router-dom';
import { DonutChart } from '../../components/DonutChart';
import DashboardTable from './DashboardTable';
import { sortByKey } from '../../utils';

const DashboardAdmin = (props) => {

    const {user} = props;

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    const [backdrop, setBackdrop] = useState(false);
  
    const [dialog, setDialog] = useState(dialogProps.init());
  
    const [snackbar, setSnackbar] = useState(snackbarProps.init());
    snackbar.hide = () => {
      setSnackbar(snackbarProps.hide());
    };
  
    const [info, setInfo] = useState(infoProps.init());
  
    const [data, setData] = useState([]);
    const [count, setCount] = useState({});
    // console.log(data);

    // var count = {
    //   totalNumberOfRegistration:0,
    //   totalApprovedCount:0,
    //   totalPendingCount:0,
    //   totalRejectedCount:0,
    //   totalMaleCount:0,
    //   totalFemaleCount:0,
    //   playerRegistrationCount:0,
    //   officialRegistrationCount:0,
    //   refereeRegistrationCount:0
    // };

    var adminIndex = -1;
    for(var i=0; i<data.length; i++) {
      if(data[i].stateName === "ADMIN") {
        setCount(data[i]);
        adminIndex = i;
        break;
      }
    }

    if(adminIndex !== -1) {
      data.splice(i, 1);
    }

    console.log(count);

    const fetchData = async () => {
      try {

        // Authenticate the google token
        const response = await API_STFI.GET_REGISTRATION_COUNT_ADMIN();

        setData(sortByKey(response.data, "stateName"));

        // Hide loader
        setLoading(false);
  
        setInfo(infoProps.close());
  
      } catch (error) {
  
        // Hide loader
        setLoading(false);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error('Server responded with an error:', error.response.status, error.response);
          setInfo(infoProps.error(error.response.statusText));
        } else if (error.request) {
          // The request was made but no response was received
          console.error('No response received from the server');
          setInfo(infoProps.error(ERROR_STR.NO_RESPONSE));
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Error setting up the request:', error.message);
          setInfo(infoProps.error(ERROR_STR.STD));
        }
      }
    }
  
    useEffect(() => {
  
      fetchData();
      
    }, []);

    const check = (num) => {
      if(!num) return 0;
      else return Math.floor(num);
    };

    return (
      <>
        {
          (loading) ?
          <Box sx={{ width: '100%', height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress />
          </Box>
          :
          <Grid
            container
            spacing={3}
          >
            {
            (data?.length)?
            <>
            <Grid
              xs={12}
              sm={6}
              lg={3}
            >
              <CountWidget
                sx={{ height: '100%' }}
                header = "Total Registrations"
                value={check(count.totalNumberOfRegistration)}
              />
            </Grid>
            <Grid
              xs={12}
              sm={6}
              lg={3}
              onClick = {() => {navigate("/registration/view?status=A");}}
            >
              <CountWidget
                sx={{ height: '100%', cursor:"pointer" }}
                header = "Approved"
                value={check(count.totalApprovedCount)}
              />
            </Grid>
            <Grid
              xs={12}
              sm={6}
              lg={3}
              onClick = {() => {navigate("/registration/view?status=P");}}
            >
              <CountWidget
                sx={{ height: '100%', cursor:"pointer"}}
                header = "Pending"
                value={check(count.totalPendingCount)}
              />
            </Grid>
            <Grid
              xs={12}
              sm={6}
              lg={3}
              onClick = {() => {navigate("/registration/view?status=R");}}
            >
              <CountWidget
                sx={{ height: '100%', cursor:"pointer"}}
                header = "Rejected"
                value={check(count.totalRejectedCount)}
              />
            </Grid>
            
            {data.map((action, index) => (
                (action.totalPendingCount)?
                <Grid
                  xs={12}
                  sm={6}
                  lg={3}
                  key={index}
                  onClick = {() => {navigate(`/registration/view?status=P&stateCode=${action.stateCode}`);}}
                >
                  
                    <CountWidget
                      key = {index}
                      sx={{ height: '100%', cursor:"pointer"}}
                      header = {"Pending"}
                      mainHeader = {action.stateName}
                      value={check(action.totalPendingCount)}
                    />
                
                </Grid>
                :
                <></>
            ))}
          <Grid
            container
            spacing={3}
            md={12}
          >

            <Grid
              xs={12}
              md={6}
            >
              <DonutChart
                chartSeries={[check(count.playerRegistrationCount), check(count.officialRegistrationCount), check(count.refereeRegistrationCount)]}
                labels={['Player', 'Official', 'Referee']}
                header="Role"
                total={check( check(count.playerRegistrationCount) + check(count.officialRegistrationCount) + check(count.refereeRegistrationCount))}
                sx={{ height: '100%' }}
              />
            </Grid>
            <Grid
              xs={12}
              md={6}
            >
              <DonutChart
                chartSeries={[check(count.totalMaleCount), check(count.totalFemaleCount)]}
                labels={['Male', 'Female']}
                header="Gender"
                total={(check(count.totalMaleCount) + check(count.totalFemaleCount))}
                sx={{ height: '100%' }}
              />
            </Grid> 
          </Grid>
          <Grid
            container
            spacing={3}
            md={12}
            sx={{pt:2}}
          >
          <Grid
            md={12}
          >
            {
              (data.length)?
              <DashboardTable data = {data}/>
              :
              <></>
            }
          </Grid>
          </Grid>
          </>
          :
          <></>
          }
          <Info {...info} />
          <Snackbar {...snackbar} />
          <Backdrop open = {backdrop}/>
          <Dialog {...dialog} /> 
          </Grid>
        }
      </>
    );
}

export default DashboardAdmin