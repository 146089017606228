import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { ERROR_STR, USER_ROLE, dialogProps, infoProps, snackbarProps } from '../../constant';
import { API_STFI } from '../../api/stfi';
import Info from '../../components/Info';
import Snackbar from '../../components/Snackbar';
import Backdrop from '../../components/Backdrop';
import Dialog from '../../components/Dialog';
import { Link, useSearchParams } from 'react-router-dom';

function applyPagination(documents, page, rowsPerPage) {
  return documents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}

const useCustomers = (data, page, rowsPerPage) => {
  return useMemo(
    () => {
      return applyPagination(data, page, rowsPerPage);
    },
    [data, page, rowsPerPage]
  );
};

const RegistrationsTable = ({user}) => {

  const [searchParams, setSearchParams] = useSearchParams();
  let updatedSearchParams = new URLSearchParams(searchParams.toString());
  const status = searchParams.get("status") || "A";
  const stateCode = searchParams.get("stateCode");
  const gender = searchParams.get("gender") || "M";
  const role = searchParams.get("role") || "P";

  const [loading, setLoading] = useState(false);

  const [backdrop] = useState(false);

  const [dialog] = useState(dialogProps.init());

  const [snackbar, setSnackbar] = useState(snackbarProps.init());
  snackbar.hide = () => {
    setSnackbar(snackbarProps.hide());
  };

  const [info, setInfo] = useState(infoProps.init());

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const tableData = useCustomers(data, page, rowsPerPage);

  const handlePageChange = useCallback(
    (event, value) => {
      setPage(value);
    },
    []
  );

  const handleRowsPerPageChange = useCallback(
    (event) => {
      setRowsPerPage(event.target.value);
    },
    []
  );

  const fetchData = useCallback(async () => {
    try {

      if(!stateCode && user.role === USER_ROLE.ADMIN || stateCode === "val") {
        setData([]);
        return;
      }

      setLoading(true);
      // Authenticate the google token
      var response;

      if(user.role === USER_ROLE.ADMIN) {
        response = await API_STFI.REGISTRATION_GET_ADMIN(stateCode, gender, status, role);
      }
      else {
        response = await API_STFI.REGISTRATION_GET_STATEADMIN(gender, status, role);
      }

      setData(response.data.registeredResponsesList);

      // Hide loader
      setLoading(false);

      setInfo(infoProps.close());

    } catch (error) {

      setData([]);
      // Hide loader
      setLoading(false);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('Server responded with an error:', error.response.status, error.response);
        setInfo(infoProps.error(error.response.statusText));
      } else if (error.request) {
        // The request was made but no response was received
        console.error('No response received from the server');
        setInfo(infoProps.error(ERROR_STR.NO_RESPONSE));
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error setting up the request:', error.message);
        setInfo(infoProps.error(ERROR_STR.STD));
      }
    }
  }, [stateCode, status, role, gender, user]);

  useEffect(() => {

    fetchData();
    
  }, [fetchData]);

  const viewMore = (url, action) => {
    updatedSearchParams.set("action", (action)?action:"preview");
    updatedSearchParams.set("url", btoa(url));
    setSearchParams(updatedSearchParams.toString());
  };


  return (
    <>
      {
        (loading) ?
        <Box sx={{ width: '100%', height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CircularProgress />
        </Box>
        :
        (data.length)?
        <Card sx={{overflowX:"auto" }}>
          <Box sx={{ minWidth: 800}}>
              <Table>
              <TableHead>
                  <TableRow>
                  <TableCell>
                    Name
                </TableCell>
                <TableCell>
                    Gmail
                </TableCell>
                {
                  (user.role === USER_ROLE.STATE_ADMIN && status === "A")?
                  <TableCell>
                      Registration id
                  </TableCell>
                  :
                  <></>
                }
                {
                  (user.role === USER_ROLE.ADMIN || status === "R")?
                  <TableCell>
                      Actions
                  </TableCell>
                  :
                  <></>
                }
                  </TableRow>
              </TableHead>
              <TableBody>
                  {tableData.map((data, i) => {

                  return (
                      <TableRow
                      hover
                      key={i}
                      >
                      <TableCell>
                        <Stack
                          alignItems="center"
                          direction="row"
                          spacing={2}
                          >
                          <Avatar sx = {{background:"#e2e2e2"}} src={(data.passportSizePhoto)}>

                          </Avatar>
                          <Typography variant="subtitle2">
                              {data.name}
                          </Typography>
                          </Stack>
                      </TableCell>
                      <TableCell>
                          {data.emailId}
                      </TableCell>
                      {
                        (user.role === USER_ROLE.STATE_ADMIN && (data.playerRegistrationId || data.officialRegistrationId || data.refereeRegistrationId ))?
                        <TableCell>
                          {data.playerRegistrationId || data.officialRegistrationId || data.refereeRegistrationId}
                        </TableCell>
                        :
                        <></>
                      }
                      <TableCell>
                        {
                          (user.role === USER_ROLE.ADMIN)?
                          <Button
                            sx = {{fontSize:12}}
                            component={Link}
                            onClick={() => {viewMore(data.viewMore)}}
                          >
                            View more
                          </Button>
                          :
                          (status === "R")?
                          <Button
                            sx = {{fontSize:12}}
                            component={Link}
                            onClick={() => {viewMore(data.viewMore, "resubmit")}}
                          >
                            Re-submit
                          </Button>
                          :
                          <></>
                        }
                      </TableCell>
                      </TableRow>
                  );
                  })}
              </TableBody>
              </Table>
          </Box>
        <TablePagination
          component="div"
          count={data.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 100]}
        />
      </Card>
        :
        ((stateCode || user.role === USER_ROLE.STATE_ADMIN) && !info.open)?
        <Typography>No registrations</Typography>
        :
        <></>
      }
      <Info {...info} />
      <Snackbar {...snackbar} />
      <Backdrop open = {backdrop}/>
      <Dialog {...dialog} /> 
    </>
  )
}

export default RegistrationsTable