import React, { useState } from 'react'
import { TextField, ToggleButton, ToggleButtonGroup, Unstable_Grid2 as Grid } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { USER_ROLE } from '../../constant';

const RegistrationFilter = (props) => {

    const {stateCodeList, user} = props;

    const [searchParams, setSearchParams] = useSearchParams();
    let updatedSearchParams = new URLSearchParams(searchParams.toString());
    const stateCodeParam = searchParams.get("stateCode");
    const roleParam = searchParams.get("role");
    const genderParam = searchParams.get("gender");
    const statusParam = searchParams.get("status");

    const [status, setStatus] = useState(statusParam || "A");
    const [stateCode, setStateCode] = useState(stateCodeParam || "val");
    const [role, setRole] = React.useState((roleParam)?roleParam:"P");
    const [gender, setGender] = React.useState((genderParam)?genderParam:"M");

    const statusOnChange = (event) => {
        setStatus(event.target.value);
        updatedSearchParams.set("status", event.target.value);
        setSearchParams(updatedSearchParams.toString());
    };

    const stateOnChange = (event) => {
        setStateCode(event.target.value);
        updatedSearchParams.set("stateCode", event.target.value);
        setSearchParams(updatedSearchParams.toString());
    };

    const roleOnChange = (event, newRole) => {

    if(newRole === null) return;

        setRole(newRole);
        updatedSearchParams.set("role", newRole);
        setSearchParams(updatedSearchParams.toString());
    };

    const genderOnChange = (event, gender) => {

        if(gender === null) return;

        setGender(gender);
        updatedSearchParams.set("gender", gender);
        setSearchParams(updatedSearchParams.toString());
    };

  return (
    <>
        <Grid
            md={12}
        >
        {
            (user.role === USER_ROLE.ADMIN)?
            <TextField
                label= "State"
                name= "state"
                size = "small"
                // fullWidth
                // sx={{width:"14em"}}
                select
                SelectProps={{ native: true }}
                onChange={stateOnChange}
                value={(stateCode)?stateCode:"val"}
                >
                <option key = {"val"} value={"val"} >Select a state</option>
                {
                (stateCodeList)?
                stateCodeList.map((option) => (
                    <option
                        key={option.stateCode}
                        value={option.stateCode}
                    >
                        {option.stateName}
                    </option>
                ))
                :
                <></>
            }
            </TextField>
            :
            <></>
        }
        </Grid>
        <Grid
            md={12}
        >
        <ToggleButtonGroup
            color="primary"
            value={status}
            exclusive
            onChange={statusOnChange} 
            aria-label="Platform"
            style={{margin:"5px 15px"}}
            size = "small"
        >
            <ToggleButton value={"A"}>Approved</ToggleButton>
            <ToggleButton value={"P"}>Pending</ToggleButton>
            <ToggleButton value={"R"}>Rejected</ToggleButton>
        </ToggleButtonGroup>
        </Grid>
        {
            (status === "A")?
            <>
            <Grid
                md={12}
            >
            <ToggleButtonGroup
                color="primary"
                value={role}
                exclusive
                onChange={roleOnChange} 
                aria-label="Platform"
                style={{margin:"5px 15px"}}
                size = "small"
            >
                <ToggleButton value={"P"}>Player</ToggleButton>
                <ToggleButton value={"O"}>official</ToggleButton>
                <ToggleButton value={"R"}>Referee</ToggleButton>
            </ToggleButtonGroup>
            </Grid>
             <Grid
             md={12}
                >
                <ToggleButtonGroup
                    color="primary"
                    value={gender}
                    exclusive
                    onChange={genderOnChange}
                    aria-label="Platform"
                    style={{margin:"5px 15px"}}
                    size = "small"
                >
                    <ToggleButton value="M">Male</ToggleButton>
                    <ToggleButton value="F">Female</ToggleButton>
                </ToggleButtonGroup>
                </Grid>
            </>
            :
            <></>
        }
    </>
  )
}

export default RegistrationFilter