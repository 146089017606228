import * as React from 'react';
import { Backdrop as BackdropMui } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

export default function Backdrop({open}) {

  return (
    <div>
        <BackdropMui
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            onClick={() => {}}
        >
            <CircularProgress color="inherit" />
        </BackdropMui>
    </div>
  );
}