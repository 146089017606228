import { Button } from '@mui/material';
import React from 'react'

function FilePreviewBtn({url, buttonLabel}) {

  return (
    <>
        {
            (url !== undefined && url !== "" && url.length > 0 && (url.indexOf("https://") !== -1 || url.indexOf("http://") !== -1))?
            <Button style={{marginTop:"1em"}} variant="outlined" size="medium" 
                onClick = {() => {

                    window.open(
                        url,
                        `_blank`,
                        `toolbar=no,
                        location=no,
                        status=no,
                        menubar=no,
                        scrollbars=yes,
                        resizable=yes,
                        width=600,
                        height=600`
                    );
                    return false;
                }}
                sx={{textAlign:"left"}}
            >
                {buttonLabel}
            </Button>
            :<></>
        }
    </>
    
  )
}

export default FilePreviewBtn