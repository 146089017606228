import { Avatar, Box, Button, Card, CardContent, Divider, Stack, SvgIcon, Typography } from '@mui/material';
import { USER_ROLE } from '../constant';

export const NewsFeedCard = (props) => {
  const { data, user, onDelete } = props;

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pb: 3
          }}
        >
          <Avatar
            sx={{width:"150px", height:"150px", backgroundSize:"cover", borderRadius:"5px"}}
            src={data.imageFileName}
            variant="square"
          />
        </Box>
        <Typography
          align="center"
          gutterBottom
          variant="h6"
        >
          {data.heading}
        </Typography>
        <Typography
          align="center"
          variant="body1"
        >
          {data.content}
        </Typography>
        {
          (user.role === USER_ROLE.ADMIN)?
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="center"
            sx={{mt:2}}
          >
          <Button onClick={() => onDelete(data.id)}>Delete</Button>
          </Stack>
          :
          <></>
        }
      </CardContent>
      <Box sx={{ flexGrow: 1 }} />
      <Divider />
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{ p: 2 }}
      >
        <Stack
          alignItems="center"
          direction="row"
          spacing={1}
        >
          <SvgIcon
            color="action"
            fontSize="small"

          >
            <svg width = "20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" >
            <path fillRule="evenodd" d="M1 8a7 7 0 1 1 14 0A7 7 0 0 1 1 8Zm7.75-4.25a.75.75 0 0 0-1.5 0V8c0 .414.336.75.75.75h3.25a.75.75 0 0 0 0-1.5h-2.5v-3.5Z" clipRule="evenodd" />
            </svg>
          </SvgIcon>
          <Typography
            color="text.secondary"
            display="inline"
            variant="body2"
          >
            {data.date}
          </Typography>
        </Stack>
        <Stack
          alignItems="center"
          direction="row"
          spacing={1}
        >
          {
            (data.file)
            ?
            <Button
              startIcon={(
                  <SvgIcon fontSize="small" >
                  <svg width = "20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" >
                  <path d="M13.75 7h-3v5.296l1.943-2.048a.75.75 0 0 1 1.114 1.004l-3.25 3.5a.75.75 0 0 1-1.114 0l-3.25-3.5a.75.75 0 1 1 1.114-1.004l1.943 2.048V7h1.5V1.75a.75.75 0 0 0-1.5 0V7h-3A2.25 2.25 0 0 0 4 9.25v7.5A2.25 2.25 0 0 0 6.25 19h7.5A2.25 2.25 0 0 0 16 16.75v-7.5A2.25 2.25 0 0 0 13.75 7Z" />
                  </svg>
                  </SvgIcon>
              )}
              target="_blank"
              href={data.file}
            >
            View more
            </Button>
            :
            <></>
          }
        </Stack>
      </Stack>
    </Card>
  );
};
