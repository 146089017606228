import { Box, Button, SvgIcon, Typography } from '@mui/material'
import React from 'react'
import PageNotFoundImg from '../assets/error-404.png'
import { Link } from 'react-router-dom'

const Info = ({open, text, back}) => {
  return (
    <>
    {
        (open)?
        <Box sx = {{display:"flex",width:"100%",height:"100%",justifyContent:"center", alignItems:"center", flexDirection: "column"}}>
            <Box>
                <img alt = "" style = {{width: "200px"}} src={PageNotFoundImg} />
            </Box>
            <Box sx={{mt:5}}>
                <Typography variant="subtitle" sx={{fontSize:25}}>{text}</Typography>
            </Box>
            {
              (back)?
              <Button
                component={Link}
                to={-1}
                sx={{ mt: 5 }}
                variant="contained"
              >
                Back
              </Button>
              :
              <Button
                component={Link}
                to={"/login"}
                startIcon={(
                  <SvgIcon fontSize="small">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                      <path fillRule="evenodd" d="M11.03 3.97a.75.75 0 010 1.06l-6.22 6.22H21a.75.75 0 010 1.5H4.81l6.22 6.22a.75.75 0 11-1.06 1.06l-7.5-7.5a.75.75 0 010-1.06l7.5-7.5a.75.75 0 011.06 0z" clipRule="evenodd" />
                    </svg>
                  </SvgIcon>
                )}
                sx={{ mt: 5 }}
                variant="contained"
              >
                Go back to login
              </Button>
            }
        </Box>
        :
        <></>
    }
    </>
  )
}

export default Info