import React from 'react'
import { Layout } from '../layouts/dashboard/layout'
import { Box, Container, Stack, Typography} from '@mui/material';
import { getUserInfo } from '../guards/auth-guard';
import { USER_ROLE } from '../constant';
import DashboardStateAdmin from '../sections/dashboard/DashboardStateAdmin';
import DashboardAdmin from '../sections/dashboard/DashboardAdmin';

const Dashboard = () => {

  const user = getUserInfo();

  return (
    <Layout user = {user} >
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 1
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={4}
            >
              <Stack spacing={1} sx={{width:"100%"}} >
                <Typography variant="h5">
                  Dashboard
                </Typography>
                <Stack
                  alignItems="center"
                  direction="row"
                  spacing={1}
                  justifyContent={"right"}
                  sx={{width:"100%"}}
                >
                </Stack>
              </Stack>
            </Stack>
            {
              (user.role === USER_ROLE.STATE_ADMIN)?
              <DashboardStateAdmin  user = {user} />
              :
              (user.role === USER_ROLE.ADMIN)?
              <DashboardAdmin user = {user} />
              :
              <></>
            }
            
          </Stack>
        </Container>
      </Box>
    </Layout>
  )
}

export default Dashboard