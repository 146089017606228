import React, { useEffect, useState } from 'react'
import Dialog from '../../components/Dialog';
import Backdrop from '../../components/Backdrop';
import Snackbar from '../../components/Snackbar';
import Info from '../../components/Info';
import { Box, CircularProgress, Unstable_Grid2 as Grid} from '@mui/material';
import { ERROR_STR, dialogButtonsProps, dialogProps, infoProps, snackbarProps } from '../../constant';
import { API_STFI } from '../../api/stfi';
import { NewsFeedCard } from '../../components/NewsFeedCard';
import { CountWidget } from '../../components/CountWidget';
import { DonutChart } from '../../components/DonutChart';
import { useNavigate } from 'react-router-dom';

const DashboardStateAdmin = (props) => {

    const {user} = props;

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    const [backdrop, setBackdrop] = useState(false);
  
    const [dialog, setDialog] = useState(dialogProps.init());
  
    const [snackbar, setSnackbar] = useState(snackbarProps.init());
    snackbar.hide = () => {
      setSnackbar(snackbarProps.hide());
    };
  
    const [info, setInfo] = useState(infoProps.init());
  
    const [data, setData] = useState([]);
    console.log(data);

    const fetchData = async () => {
      try {

        // Authenticate the google token
        const response = await API_STFI.GET_REGISTRATION_COUNT_STATE_ADMIN();
  
        setData(response.data);
  
        // Hide loader
        setLoading(false);
  
        setInfo(infoProps.close());
  
      } catch (error) {
  
        // Hide loader
        setLoading(false);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error('Server responded with an error:', error.response.status, error.response);
          setInfo(infoProps.error(error.response.statusText));
        } else if (error.request) {
          // The request was made but no response was received
          console.error('No response received from the server');
          setInfo(infoProps.error(ERROR_STR.NO_RESPONSE));
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Error setting up the request:', error.message);
          setInfo(infoProps.error(ERROR_STR.STD));
        }
      }
    }
  
    useEffect(() => {
  
      fetchData();
      
    }, []);

    const check = (num) => {
      if(!num) return 0;
      else return num;
    };

    return (
      <>
        {
          (loading) ?
          <Box sx={{ width: '100%', height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress />
          </Box>
          :
          <Grid
            container
            spacing={3}
          >
            <Grid
              xs={12}
              sm={6}
              lg={3}
            >
              <CountWidget
                sx={{ height: '100%' }}
                header = "Total Registrations"
                value={check(data.totalNumberOfRegistration)}
              />
            </Grid>
            <Grid
              xs={12}
              sm={6}
              lg={3}
              onClick = {() => {navigate("/registration/view?status=A");}}
            >
              <CountWidget
                sx={{ height: '100%', cursor:"pointer" }}
                header = "Approved"
                value={check(data.totalApprovedCount)}
              />
            </Grid>
            <Grid
              xs={12}
              sm={6}
              lg={3}
              onClick = {() => {navigate("/registration/view?status=P");}}
            >
              <CountWidget
                sx={{ height: '100%', cursor:"pointer"}}
                header = "Pending"
                value={check(data.totalPendingCount)}
              />
            </Grid>
            <Grid
              xs={12}
              sm={6}
              lg={3}
              onClick = {() => {navigate("/registration/view?status=R");}}
            >
              <CountWidget
                sx={{ height: '100%', cursor:"pointer"}}
                header = "Rejected"
                value={check(data.totalRejectedCount)}
              />
            </Grid>

            <Grid
              xs={12}
              md={6}
            >
              <DonutChart
                chartSeries={[check(data.playerRegistrationCount), check(data.officialRegistrationCount), check(data.refereeRegistrationCount)]}
                labels={['Player', 'Official', 'Referee']}
                header="Role"
                total={check(data.playerRegistrationCount) + check(data.officialRegistrationCount) + check(data.refereeRegistrationCount)}
                sx={{ height: '100%' }}
              />
            </Grid>
            <Grid
              xs={12}
              md={6}
            >
              <DonutChart
                chartSeries={[check(data.totalMaleCount), check(data.totalFemaleCount)]}
                labels={['Male', 'Female']}
                header="Gender"
                total={check(data.totalMaleCount) + check(data.totalFemaleCount)}
                sx={{ height: '100%' }}
              />
            </Grid>
          
          <Info {...info} />
          <Snackbar {...snackbar} />
          <Backdrop open = {backdrop}/>
          <Dialog {...dialog} /> 
          </Grid>
        }
      </>
    );
}

export default DashboardStateAdmin