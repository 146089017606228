import React from 'react'
import { Layout } from '../layouts/dashboard/layout'
import { Box, Button, Container, Stack, Typography} from '@mui/material';
import { getUserInfo } from '../guards/auth-guard';
import { Link, useSearchParams } from 'react-router-dom';
import { USER_ROLE } from '../constant';
import GalleryAdd from '../sections/gallery/GalleryAdd';
import GalleryView from '../sections/gallery/GalleryView';

const Gallery = () => {

  const user = getUserInfo();

  const [searchParams] = useSearchParams();
  const action = searchParams.get("action");

  const ActionButton = () => {

    if(user.role === USER_ROLE.ADMIN) {

      if(action === "add") {
        return(
          <Button
            component={Link}
            to={"/gallery"}
            sx={{ mt: 3 }}
            variant="outlined"
          >
            Back
          </Button>
        );
      }
      else {
        return(
          <Button
            component={Link}
            to={"/gallery?action=add"}
            sx={{ mt: 3 }}
            variant="outlined"
          >
            Add
          </Button>
        );
      }
    }
    else return <></>;
  }

  return (
    <Layout user = {user} >
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 1
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={4}
            >
              <Stack spacing={1} sx={{width:"100%"}} >
                <Typography variant="h5">
                  Gallery
                </Typography>
                <Stack
                  alignItems="center"
                  direction="row"
                  spacing={1}
                  justifyContent={"right"}
                  sx={{width:"100%"}}
                >
                  <ActionButton />
                </Stack>
              </Stack>
            </Stack>
            {
              (action)
              ?
              <GalleryAdd  user = {user} />
              :
              <GalleryView user = {user} />
            }
            
          </Stack>
        </Container>
      </Box>
    </Layout>
  )
}

export default Gallery