import { useNavigate } from 'react-router-dom';
import { STORAGE } from '../constant';
import { useEffect } from 'react';

export const getUserInfo = () => {

  try {
    const user = JSON.parse(atob(localStorage.getItem(STORAGE.USERINFO)));
    return user;
  }
  catch(e) {
    return null;
  }
};

export const AuthGuard = (props) => {

  const { children } = props;

  const navigate = useNavigate();

  const user = getUserInfo();

  useEffect(
    () => {
      if(!user) {
        navigate("/login");
      }
    },
    [navigate, user]
  );

  if(!user) {
    return <></>;
  }

  return children;
};