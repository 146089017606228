import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Unstable_Grid2 as Grid, TextField } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ERROR_STR, HELPER_TEXT, RULE_PATTERN_VALUE, USER_FORM_KEY, USER_ROLE, infoProps, snackbarProps } from '../../constant';
import Backdrop from '../../components/Backdrop';
import Snackbar from '../../components/Snackbar';
import { API_STFI } from '../../api/stfi';
import Info from '../../components/Info';
import { sortByKey } from '../../utils';

const UsersAdd = (props) => {

  const {action, email} = props;

  const formRef = useRef(null);

  const [loading, setLoading] = useState(true);
  const [states, setStates] = useState([]);

  const initState = useMemo(() => ({
    "name": "",
    "email": "",
    "role": 1,
    "stateCode": "",
    "stateName": ""
  }), []);

  const [values, setValues] = useState(initState);

  const [backdrop, setBackdrop] = useState(false);
  
  const [snackbar, setSnackbar] = useState(snackbarProps.init());
  snackbar.hide = () => {
    setSnackbar(snackbarProps.hide());
  };

  const [info, setInfo] = useState(infoProps.init());

  const [errors] = useState({});

  const handleChange = useCallback(
    (event) => {

      const { name, value } = event.target;

      setValues((prevState) => ({
        ...prevState,
        [name]: value
      }));
    },
    [setValues]
  );

  const submitData = useCallback(async (formData) => {
    try {
      // Authenticate the google token
      if (action === "edit") await API_STFI.USERS_UPDATE(formData);
      else await API_STFI.USERS_ADD(formData);
  
      // Hide loader
      setBackdrop(false);
  
      setSnackbar(snackbarProps.success("Form submitted successfully"));
  
      if (action === "add") {
        formRef.current.reset();
        setValues(initState);
      }
    } catch (error) {
      // Hide loader
      setBackdrop(false);
      if (error.response) {
        console.error('Server responded with an error:', error.response.status, error.response);
        setInfo(infoProps.error(error.response.statusText));
      } else if (error.request) {
        console.error('No response received from the server');
        setInfo(infoProps.error(ERROR_STR.NO_RESPONSE));
      } else {
        console.error('Error setting up the request:', error.message);
        setInfo(infoProps.error(ERROR_STR.STD));
      }
    }
  }, [action, setBackdrop, setSnackbar, setValues, formRef, initState]);
  
  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
  
      const data = {};
      const formElements = event.target.elements;
  
      for (let i = 0; i < formElements.length; i++) {
        const element = formElements[i];
  
        if (element.name === USER_FORM_KEY.STATECODE) {
          for (let j = 0; j < states.length; j++) {
            if (states[j].stateCode === element.value) {
              data[USER_FORM_KEY.STATECODE] = states[j].stateCode;
              data[USER_FORM_KEY.STATENAME] = states[j].stateName;
              break;
            }
          }
        } else if (element.name) {
          data[element.name] = element.value;
        }
      }
  
      setBackdrop(true);
      submitData(data);
    },
    [states, submitData]
  );
  
  const fetchData = useCallback(async () => {
    
    try {
      // Authenticate the google token
      const response = await API_STFI.STATES();
      setStates(sortByKey(response.data, "stateName"));
  
      if (action === "edit" && email) {
        const response2 = await API_STFI.USERS_GET(email);
        setValues(response2.data);
      }
  
      // Hide loader
      setLoading(false);
  
      setInfo(infoProps.close());
    } catch (error) {
      // Hide loader
      setLoading(false);
      if (error.response) {
        console.error('Server responded with an error:', error.response.status, error.response);
        setInfo(infoProps.error(error.response.statusText));
      } else if (error.request) {
        console.error('No response received from the server');
        setInfo(infoProps.error(ERROR_STR.NO_RESPONSE));
      } else {
        console.error('Error setting up the request:', error.message);
        setInfo(infoProps.error(ERROR_STR.STD));
      }
    }
  }, [action, email]);
  
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  
  return (
    <>
    {
      (loading) ?
      <Box sx={{ width: '100%', height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <CircularProgress />
      </Box>
      :
      (info.open)?
      <Info {...info}/>
      :
    <form
      onSubmit={handleSubmit}
      ref={formRef}
    >
      <Card sx = {{height:"100%"}}>
        <CardHeader
          subheader = {"Enter the details to add a new user"}
          title = {"Add User"}
        />
        <CardContent sx={{ pt: 0 }}>
            <Box sx={{ m: -1.5 }}>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label={"Name"}
                        name={USER_FORM_KEY.NAME}
                        required
                        onChange={handleChange}
                        value={values[USER_FORM_KEY.NAME]}
                        error={errors[USER_FORM_KEY.NAME]}
                        helperText={HELPER_TEXT.MAX_64}
                        inputProps={{ maxLength: 64 }}
                      />
                  </Grid>
                  <Grid
                      xs={12}
                  >
                      <TextField
                        fullWidth
                        label= {"Role"}
                        name= {USER_FORM_KEY.ROLE}
                        required
                        select
                        onChange={handleChange}
                        SelectProps={{ native: true }}
                        value={values[USER_FORM_KEY.ROLE]}
                      >
                      <option
                        value={USER_ROLE.STATE_ADMIN}
                      >
                        State Admin
                      </option>
                      <option
                        value={USER_ROLE.ADMIN}
                      >
                        Admin
                      </option>
                      </TextField>
                  </Grid>
                  <Grid
                      xs={12}
                  >
                    <TextField
                      fullWidth
                      label= {"State"}
                      name= {USER_FORM_KEY.STATECODE}
                      required
                      select
                      onChange={handleChange}
                      SelectProps={{ native: true }}
                      value={(values[USER_FORM_KEY.STATECODE])?values[USER_FORM_KEY.STATECODE]:(states.length)?states[0].stateCode:""}
                    >
                    {states.map((option) => (
                      <option
                        key={option.stateCode}
                        value={option.stateCode}
                      >
                        {option.stateName}
                      </option>
                    ))}
                    </TextField>
                  </Grid>
                  <Grid
                      xs={12}
                    >
                    <TextField
                      fullWidth
                      label={"Gmail"}
                      name={USER_FORM_KEY.EMAIL}
                      required
                      onChange={handleChange}
                      value={values[USER_FORM_KEY.EMAIL]}
                      error={errors[USER_FORM_KEY.EMAIL]}
                      helperText={errors[USER_FORM_KEY.EMAIL] ? HELPER_TEXT.GMAIL : ''}
                      inputProps={{ pattern: RULE_PATTERN_VALUE.GMAIL }}
                    />
                  </Grid>
                </Grid>
            </Box>
        </CardContent>
    </Card>
      <Box sx={{ mt: 5, textAlign: "center" }}>
				<Button type='submit' variant='contained'>Proceed</Button>
			</Box>
      <Snackbar {...snackbar} />
      <Backdrop open = {backdrop}/>
    </form>
  }
  </>
  )
}

export default UsersAdd