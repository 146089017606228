export const insertSpace = (str) => {
    if(!str) return "";
    str = str.charAt(0).toUpperCase() + str.slice(1);
    str = str.replace(/([a-z])([A-Z])/g, '$1 $2');
    return str;
};

export const sortByKey = (arr, key) => {
    return arr.sort(function(a,b) {
        var x = a[key].toLowerCase();
        var y = b[key].toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
    });
};
