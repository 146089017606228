export const Logo = () => {

  return (
    <svg width="28" height="32" viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
      <path d="M9.85449 15.8307C9.7045 16.1312 9.55475 16.4225 9.41182 16.7172C9.12267 17.3133 8.84788 17.9163 8.58734 18.5259C8.15053 19.5479 7.75249 20.5852 7.3963 21.6388C7.27198 22.0066 7.15548 22.377 7.03468 22.746C7.01478 22.8067 6.99448 22.8678 6.96844 22.926C6.92627 23.0203 6.88378 23.0342 6.79085 22.9865C6.74775 22.9644 6.70601 22.9386 6.66666 22.9102C6.42975 22.7392 6.19119 22.5704 5.95808 22.3943C5.70395 22.2022 5.45501 22.0031 5.20419 21.8066C5.17476 21.7835 5.14712 21.758 5.11975 21.7324C5.04827 21.6656 5.02204 21.5816 5.0368 21.4859C5.04423 21.4378 5.05704 21.3899 5.07246 21.3437C5.41683 20.3101 5.79635 19.29 6.21274 18.284C6.52972 17.5181 6.86696 16.7614 7.22483 16.0143C7.80104 14.8114 8.42598 13.6348 9.10336 12.4872C9.32908 12.1047 9.56226 11.7268 9.7931 11.3475C9.82427 11.2963 9.85986 11.2475 9.89676 11.2003C9.94022 11.1446 10.0017 11.128 10.072 11.1903C10.0997 11.2149 10.1228 11.2455 10.1454 11.2754C10.5214 11.7738 10.8968 12.2726 11.2728 12.771C11.325 12.8402 11.3711 12.9128 11.4101 12.994C11.3714 13.0961 11.316 13.1899 11.2607 13.2833C10.9095 13.8757 10.5705 14.4752 10.2458 15.0829C10.1145 15.3285 9.98701 15.5762 9.85449 15.8307Z" fill="#6366F1"/>
      <path d="M13.598 5.51022C13.6483 5.49232 13.6898 5.47562 13.7324 5.46247C13.8465 5.42727 13.9465 5.45977 14.0339 5.53513C14.0763 5.57168 14.1149 5.61338 14.1517 5.65583C14.6149 6.18938 15.068 6.73153 15.5094 7.28367C16.2867 8.25602 17.027 9.25636 17.7251 10.2887C18.2983 11.1364 18.8393 12.0049 19.3545 12.8899C19.7047 13.4916 20.0421 14.1005 20.361 14.7199C20.3868 14.7699 20.4112 14.8214 20.4281 14.8749C20.449 14.9409 20.4308 14.9732 20.3632 14.9943C20.31 15.011 20.2549 15.0231 20.1998 15.0315C19.9749 15.0656 19.7496 15.0974 19.5245 15.1302C19.2847 15.1651 19.0448 15.2003 18.805 15.2353C18.6352 15.26 18.4655 15.2846 18.2957 15.3096C18.2701 15.3134 18.2442 15.3173 18.2192 15.3242C18.1534 15.3422 18.0998 15.3188 18.0594 15.2695C18.0264 15.2291 17.997 15.1841 17.9745 15.1369C17.8141 14.7999 17.6238 14.4795 17.4393 14.1561C16.999 13.3842 16.5326 12.6287 16.044 11.8871C15.2525 10.6859 14.4076 9.52449 13.5045 8.40683C12.9917 7.77222 12.4575 7.15627 11.9118 6.5505C11.8743 6.5089 11.8385 6.46426 11.8103 6.41603C11.7759 6.35736 11.7881 6.30676 11.8434 6.26849C11.883 6.24111 11.9273 6.2194 11.9718 6.20076C12.3401 6.04639 12.7095 5.89452 13.0775 5.73933C13.2495 5.66683 13.4191 5.58864 13.598 5.51022Z" fill="#6366F1"/>
      <path d="M12.2907 21.1789C12.4256 20.8503 12.557 20.5297 12.6885 20.2091C12.6957 20.1917 12.7037 20.1746 12.7108 20.1572C12.7491 20.0632 12.8229 20.0168 12.9186 20.0091C13.004 20.0021 13.0901 20.0033 13.1759 20.0035C13.3849 20.0038 13.5939 20.0063 13.8029 20.0068C14.0567 20.0074 14.3105 20.0083 14.5644 20.0066C14.8068 20.0049 15.0494 20.0037 15.2917 19.9959C15.6643 19.9839 16.0369 19.9694 16.4092 19.9505C16.7331 19.9341 17.0568 19.9134 17.3802 19.8885C17.6738 19.8659 17.9669 19.8355 18.2602 19.8076C18.4346 19.791 18.6092 19.7746 18.7833 19.7547C19.0055 19.7293 19.2274 19.7011 19.4492 19.6728C19.6305 19.6496 19.8117 19.6261 19.9926 19.5999C20.2731 19.5594 20.5539 19.5195 20.8337 19.4737C21.1612 19.4202 21.4883 19.3639 21.8145 19.3029C22.1773 19.2351 22.539 19.1616 22.9009 19.0888C23.08 19.0528 23.2582 19.012 23.4373 18.9757C23.4807 18.9669 23.5261 18.9639 23.5705 18.9648C23.6239 18.9659 23.6531 18.9903 23.6636 19.0448C23.6722 19.0888 23.6758 19.135 23.6732 19.1797C23.6669 19.2927 23.6576 19.4057 23.6463 19.5183C23.6271 19.7099 23.6084 19.9015 23.5843 20.0925C23.5546 20.3284 23.5204 20.5637 23.4867 20.7991C23.4718 20.9037 23.4521 21.0076 23.4368 21.1122C23.4154 21.259 23.3276 21.3387 23.1889 21.3663C22.8778 21.428 22.5669 21.4905 22.2553 21.5488C21.9694 21.6022 21.6831 21.6535 21.3961 21.7003C21.0795 21.752 20.7622 21.7987 20.4449 21.8451C20.1939 21.8819 19.9427 21.9167 19.6911 21.949C19.4691 21.9776 19.2466 22.0019 19.0243 22.0273C18.8501 22.0472 18.6759 22.0662 18.5017 22.0852C18.4127 22.0949 18.3237 22.1052 18.2345 22.1123C17.9038 22.1384 17.5731 22.1667 17.242 22.1879C16.8589 22.2125 16.4755 22.2345 16.0919 22.2501C15.7752 22.2629 15.4581 22.2699 15.1412 22.2707C14.126 22.2732 13.1108 22.2717 12.0956 22.2713C12.0585 22.2713 12.0204 22.2714 11.9845 22.2634C11.9122 22.2472 11.8857 22.2011 11.9051 22.1292C11.9159 22.0893 11.9308 22.0503 11.9466 22.0121C12.0597 21.7369 12.1736 21.4619 12.2907 21.1789Z" fill="#6366F1"/>
      <path d="M12.0874 8.37901C12.7126 9.11676 13.3088 9.86767 13.8777 10.6407C14.6429 11.6803 15.3639 12.7502 16.0254 13.8606C16.2932 14.3101 16.5541 14.7638 16.817 15.2161C16.8414 15.2581 16.8615 15.3035 16.8775 15.3495C16.8935 15.3955 16.8748 15.4315 16.8274 15.4465C16.7888 15.4588 16.7478 15.4673 16.7074 15.4693C16.3723 15.4866 16.0369 15.5002 15.7019 15.5183C15.3594 15.5368 15.0166 15.5527 14.6751 15.5827C14.4994 15.5981 14.3811 15.5341 14.2937 15.3864C14.1633 15.1662 14.0308 14.9472 13.897 14.7291C13.3828 13.8912 12.8344 13.0767 12.2577 12.2816C11.4706 11.1964 10.6296 10.1557 9.73886 9.15634C9.45396 8.83672 9.15914 8.52618 8.86962 8.21076C8.83679 8.175 8.80476 8.13716 8.77963 8.09576C8.73926 8.02924 8.7493 7.97632 8.80865 7.92541C8.83685 7.90122 8.86922 7.88144 8.90142 7.86269C9.42184 7.55959 9.9425 7.25688 10.4633 6.95448C10.4955 6.93577 10.5283 6.91664 10.5631 6.90415C10.6414 6.87605 10.718 6.88134 10.7863 6.93308C10.819 6.95783 10.8504 6.98531 10.8783 7.01544C11.2889 7.45942 11.6905 7.91172 12.0874 8.37901Z" fill="#6366F1"/>
      <path d="M14.6059 18.945C14.2369 18.95 13.8789 18.9556 13.5209 18.9592C13.4691 18.9597 13.4165 18.9542 13.3655 18.9447C13.3082 18.934 13.2919 18.903 13.3112 18.8452C13.3255 18.8025 13.3444 18.761 13.365 18.7209C13.5096 18.4397 13.6553 18.1591 13.8004 17.8781C13.9646 17.5605 14.1331 17.245 14.291 16.9242C14.3904 16.722 14.5405 16.6276 14.763 16.6296C15.0165 16.6319 15.2703 16.6252 15.5237 16.6161C15.844 16.6046 16.1643 16.5895 16.4843 16.571C16.7783 16.5541 17.0722 16.5337 17.3657 16.509C17.6369 16.4862 17.9076 16.4566 18.1783 16.4282C18.4045 16.4044 18.6306 16.3802 18.8563 16.3522C19.0895 16.3232 19.3224 16.2923 19.5547 16.2573C19.8756 16.209 20.196 16.1578 20.5161 16.1046C21.009 16.0225 21.5003 15.9306 21.9884 15.823C22.4871 15.713 22.984 15.5941 23.4817 15.4792C23.518 15.4708 23.5544 15.4619 23.5911 15.4559C23.6984 15.4382 23.7637 15.4852 23.7809 15.5952C23.7873 15.6359 23.7866 15.678 23.7867 15.7195C23.787 16.2562 23.7868 16.793 23.787 17.3297C23.7871 17.409 23.7921 17.4885 23.7892 17.5677C23.7854 17.6709 23.7363 17.7471 23.641 17.7883C23.6035 17.8045 23.5646 17.8185 23.5251 17.8282C23.4273 17.8521 23.3291 17.8744 23.2305 17.8947C22.7191 18.0001 22.2086 18.1103 21.6958 18.208C21.2418 18.2944 20.7857 18.3707 20.3294 18.4439C19.9316 18.5078 19.5329 18.5663 19.1334 18.6176C18.7636 18.6651 18.3924 18.702 18.0214 18.7397C17.7246 18.7699 17.4275 18.7971 17.1302 18.8204C16.8515 18.8421 16.5723 18.8588 16.2931 18.8744C15.9954 18.891 15.6975 18.9053 15.3996 18.9179C15.1388 18.9289 14.8778 18.9361 14.6059 18.945Z" fill="#6366F1"/>
      <path d="M10.5061 22.8859C10.3327 23.4411 10.1614 23.9875 9.9901 24.534C9.97544 24.5807 9.96259 24.6282 9.94601 24.6742C9.93461 24.7059 9.91988 24.7366 9.90369 24.7661C9.8789 24.8113 9.83672 24.8303 9.78988 24.8168C9.7405 24.8026 9.69181 24.7812 9.64735 24.755C9.47011 24.6506 9.29581 24.5411 9.11826 24.4373C8.7987 24.2505 8.47752 24.0664 8.15718 23.8809C8.11837 23.8585 8.07892 23.8365 8.04268 23.8102C7.95203 23.7444 7.91466 23.6553 7.93954 23.5435C7.95181 23.4884 7.96623 23.4335 7.98278 23.3795C8.26891 22.4461 8.5793 21.521 8.92312 20.6077C9.3387 19.5037 9.79573 18.4177 10.2991 17.3516C10.8027 16.2852 11.3499 15.2424 11.942 14.2238C11.9778 14.1622 12.0173 14.1023 12.0603 14.0457C12.0921 14.0038 12.1293 14.0047 12.1639 14.0453C12.1978 14.0852 12.2288 14.1282 12.2566 14.1728C12.6112 14.741 12.9657 15.3092 13.3179 15.8788C13.371 15.9647 13.4127 16.0579 13.4616 16.1514C13.4191 16.2377 13.3807 16.3235 13.3358 16.4057C13.0216 16.9803 12.7211 17.5622 12.4356 18.1519C11.8643 19.3317 11.3484 20.5358 10.8991 21.7687C10.7649 22.1368 10.6383 22.5076 10.5061 22.8859Z" fill="#6366F1"/>
      <path d="M23.3035 14.4111C22.8194 14.527 22.3443 14.6403 21.8692 14.7532C21.8293 14.7627 21.7892 14.773 21.7486 14.7769C21.6575 14.7857 21.5782 14.7574 21.5209 14.683C21.4913 14.6446 21.4651 14.6025 21.4432 14.5591C21.1397 13.9573 20.8214 13.3636 20.492 12.776C20.1404 12.1488 19.7744 11.5303 19.3938 10.9207C19.2982 10.7675 19.2005 10.6156 19.1044 10.4627C19.0824 10.4278 19.0608 10.3922 19.0425 10.3552C18.9942 10.2577 19.0053 10.165 19.0711 10.0796C19.0941 10.0499 19.1198 10.0224 19.1451 9.9946C19.4935 9.61108 19.8421 9.22766 20.1907 8.84432C20.2185 8.81378 20.2461 8.78295 20.2756 8.75413C20.3565 8.67495 20.4555 8.66019 20.5639 8.71645C20.6069 8.73872 20.6466 8.76764 20.6867 8.79524C21.1247 9.09715 21.553 9.41284 21.9695 9.74448C22.3448 10.0434 22.7153 10.3486 23.0868 10.6526C23.1298 10.6878 23.169 10.7297 23.2031 10.7739C23.2447 10.828 23.2674 10.8921 23.2795 10.9604C23.3218 11.1982 23.3682 11.4352 23.4083 11.6734C23.4533 11.9413 23.4958 12.2098 23.5343 12.4787C23.5707 12.7327 23.6021 12.9875 23.6325 13.2423C23.6643 13.5084 23.6924 13.7749 23.7221 14.0413C23.7242 14.06 23.727 14.0788 23.7278 14.0976C23.7336 14.227 23.694 14.2958 23.5715 14.3403C23.4879 14.3707 23.399 14.3863 23.3035 14.4111Z" fill="#6366F1"/>
      <path d="M12.2963 26.0643C11.8816 25.8739 11.4747 25.6869 11.0681 25.4993C11.0343 25.4837 11 25.4677 10.9698 25.4463C10.9022 25.3984 10.8743 25.3321 10.8945 25.2491C10.9025 25.2161 10.9131 25.1838 10.9228 25.1513C11.0826 24.6172 11.2428 24.0832 11.4019 23.5489C11.4576 23.3616 11.5245 23.3132 11.7165 23.3144C11.9812 23.3161 12.2446 23.3407 12.5087 23.3547C12.6166 23.3604 12.7246 23.3634 12.8326 23.368C13.2948 23.3879 13.7572 23.3831 14.2195 23.3826C14.4209 23.3824 14.6222 23.3795 14.8236 23.3763C15.1255 23.3716 15.4275 23.3684 15.7293 23.3592C15.9228 23.3533 16.1161 23.3374 16.3095 23.3267C16.3988 23.3218 16.4882 23.3163 16.5776 23.3156C16.6943 23.3146 16.775 23.374 16.8235 23.4794C16.8391 23.5135 16.8525 23.549 16.8632 23.5849C17.0149 24.0901 17.166 24.5953 17.3167 25.1007C17.3296 25.1439 17.3412 25.1878 17.3486 25.2322C17.3612 25.3076 17.3396 25.3733 17.2869 25.4291C17.2471 25.4711 17.1977 25.4968 17.1467 25.5204C16.913 25.6286 16.68 25.7382 16.4452 25.8441C15.8055 26.1328 15.1568 26.3989 14.4984 26.641C14.4249 26.668 14.3507 26.6933 14.2774 26.7208C14.1828 26.7561 14.0876 26.7588 13.9925 26.7259C13.8058 26.6613 13.6185 26.5982 13.4333 26.529C13.0524 26.3868 12.6764 26.2319 12.2963 26.0643Z" fill="#6366F1"/>
      <path d="M26.5371 14.1838C26.5862 14.2414 26.6304 14.2926 26.6742 14.3442C26.7843 14.4739 26.8495 14.622 26.8641 14.7943C26.8863 15.0576 26.9036 15.3211 26.9093 15.5854C26.9131 15.7628 26.9228 15.9401 26.9219 16.1174C26.9203 16.4194 26.907 16.721 26.8877 17.0225C26.8703 17.2939 26.8449 17.5644 26.8101 17.8339C26.738 18.3923 26.6308 18.9438 26.4871 19.4881C26.2708 20.3075 25.973 21.0955 25.6006 21.8547C25.4165 22.2302 25.2144 22.5958 24.9941 22.9509C24.9624 23.0019 24.9243 23.0489 24.8883 23.0969C24.8797 23.1084 24.8672 23.1169 24.8517 23.1312C24.8426 23.1096 24.8348 23.0937 24.829 23.0771C24.7238 22.7745 24.6279 22.4692 24.5611 22.155C24.4876 21.8093 24.4614 21.4597 24.4899 21.1077C24.5038 20.9351 24.5373 20.7641 24.5617 20.5923C24.5956 20.3533 24.6314 20.1145 24.6624 19.875C24.692 19.6467 24.7185 19.418 24.7426 19.189C24.7627 18.9976 24.7813 18.8058 24.7935 18.6137C24.8147 18.2823 24.8326 17.9506 24.8477 17.6188C24.8576 17.4001 24.8612 17.1811 24.8655 16.9622C24.8689 16.7885 24.8717 16.6147 24.8702 16.4409C24.8679 16.1803 24.8671 15.9195 24.8559 15.6592C24.8399 15.2897 24.8205 14.9203 24.7922 14.5516C24.7656 14.2055 24.7255 13.8604 24.6915 13.5148C24.6734 13.3307 24.6561 13.1466 24.6373 12.9626C24.6112 12.7073 24.5838 12.4522 24.5577 12.1969C24.5549 12.1689 24.5573 12.1403 24.5573 12.0982C24.5963 12.133 24.6274 12.1587 24.6561 12.1869C24.9854 12.5105 25.3155 12.8333 25.6431 13.1587C25.8777 13.3918 26.0882 13.6473 26.3005 13.9009C26.3777 13.9931 26.4548 14.0854 26.5371 14.1838Z" fill="#6366F1"/>
      <path d="M8.59137 11.263C8.10398 12.0639 7.63889 12.8683 7.20855 13.6928C7.05362 13.9897 6.90451 14.2896 6.7525 14.588C6.73373 14.6249 6.71499 14.6619 6.69361 14.6972C6.63691 14.7909 6.55368 14.8291 6.4465 14.8131C6.42445 14.8099 6.40243 14.8056 6.38088 14.7998C5.84388 14.6573 5.30265 14.5328 4.76175 14.4068C4.71845 14.3967 4.67507 14.3841 4.63421 14.3666C4.53786 14.3256 4.49017 14.251 4.49603 14.1438C4.49705 14.125 4.49931 14.1062 4.50113 14.0874C4.5391 13.6968 4.5811 13.3067 4.63177 12.9174C4.71412 12.2848 4.82138 11.6563 4.93253 11.0285C4.95226 10.917 5.00063 10.8214 5.07957 10.7418C5.11125 10.7099 5.14442 10.6794 5.17833 10.6499C5.89718 10.0242 6.63659 9.42477 7.40701 8.86509C7.45548 8.82988 7.50371 8.79427 7.55329 8.76072C7.58106 8.74193 7.61039 8.72484 7.64072 8.71077C7.72573 8.67136 7.80779 8.6774 7.88273 8.7359C7.91207 8.75881 7.93968 8.78471 7.9649 8.81223C8.28197 9.15813 8.5983 9.50473 8.91523 9.85076C8.96595 9.90613 9.01916 9.95916 9.07023 10.0142C9.09057 10.0361 9.10958 10.0596 9.12688 10.0841C9.1865 10.1685 9.19258 10.259 9.15042 10.3521C9.13184 10.3931 9.10856 10.4321 9.08482 10.4704C8.92223 10.7326 8.75895 10.9943 8.59137 11.263Z" fill="#6366F1"/>
      <path d="M10.2292 5.86731C9.46892 6.26385 8.73561 6.69106 8.01985 7.14927C7.21208 7.6664 6.43154 8.22302 5.67202 8.81011C5.60442 8.86236 5.53597 8.91348 5.47239 8.96173C5.43851 8.94987 5.44561 8.92835 5.44549 8.91137C5.44449 8.77006 5.47413 8.63355 5.51549 8.49994C5.68492 7.95264 5.8532 7.40495 6.02784 6.85936C6.11168 6.59744 6.20884 6.33991 6.2998 6.08033C6.37587 5.86324 6.50827 5.69366 6.6968 5.56172C7.26218 5.16602 7.84881 4.80696 8.46449 4.49779C9.37664 4.03974 10.3299 3.69758 11.3247 3.47694C11.6925 3.39535 12.0631 3.32755 12.4378 3.28622C12.7639 3.25025 13.0901 3.21499 13.4166 3.18182C13.5092 3.17241 13.6026 3.17033 13.6958 3.16684C13.8075 3.16265 13.9194 3.15641 14.0311 3.15849C14.21 3.16183 14.3888 3.17079 14.5675 3.17867C14.5931 3.17979 14.6196 3.18467 14.6435 3.19379C14.7065 3.21783 14.7272 3.28632 14.6906 3.34181C14.6865 3.34805 14.6813 3.35362 14.6762 3.35914C14.5694 3.47507 14.4642 3.5925 14.3555 3.70653C14.0622 4.01415 13.719 4.25223 13.3452 4.44799C13.0898 4.58179 12.8227 4.68753 12.5559 4.79481C11.7796 5.10703 11.0204 5.45786 10.2767 5.84311C10.2634 5.84999 10.2499 5.8565 10.2292 5.86731Z" fill="#6366F1"/>
      <path d="M8.0267 27.5072C7.23686 27.0649 6.50139 26.5572 5.82039 25.9696C4.94902 25.2178 4.19214 24.3619 3.54843 23.4032C3.33229 23.0814 3.13116 22.7502 2.94069 22.4123C2.90934 22.3566 2.87325 22.3038 2.83962 22.2494C2.82579 22.2271 2.81259 22.2044 2.79929 22.1817C2.75031 22.0982 2.82729 21.9867 2.92519 22.0034C2.93254 22.0046 2.94041 22.004 2.94735 22.0063C3.09304 22.0553 3.24665 22.0672 3.39482 22.1049C3.76779 22.1997 4.10867 22.3656 4.42946 22.5774C4.6298 22.7098 4.81749 22.8591 5.00484 23.0091C6.29565 24.0426 7.67026 24.9461 9.12066 25.7325C10.0381 26.2299 10.9799 26.6746 11.9412 27.0774C12.0066 27.1048 12.0749 27.1252 12.1392 27.1549C12.1999 27.183 12.2574 27.2182 12.3152 27.252C12.3267 27.2587 12.3336 27.2734 12.3396 27.2807C12.3375 27.3086 12.3195 27.3068 12.3065 27.3108C11.9277 27.4248 11.5436 27.5172 11.1584 27.606C10.7407 27.7023 10.3223 27.7967 9.90701 27.9031C9.50689 28.0056 9.11697 27.9824 8.7303 27.8451C8.48654 27.7586 8.25893 27.6378 8.0267 27.5072Z" fill="#6366F1"/>
      <path d="M18.7491 26.3577C18.6433 25.9393 18.5457 25.5284 18.4333 25.1216C18.2989 24.635 18.1521 24.1518 18.0099 23.6674C17.994 23.6133 17.9729 23.5607 17.9549 23.5072C17.9441 23.4751 17.9313 23.4431 17.9252 23.41C17.908 23.3173 17.9434 23.2545 18.0313 23.2253C18.0661 23.2137 18.1039 23.2078 18.1407 23.2059C18.4609 23.1891 18.7799 23.1598 19.098 23.1194C19.3494 23.0875 19.601 23.0576 19.8528 23.0285C19.9082 23.0221 19.9644 23.021 20.0203 23.0205C20.1124 23.0198 20.1751 23.0669 20.2127 23.1499C20.2251 23.1772 20.2356 23.2056 20.2446 23.2342C20.4455 23.874 20.637 24.5166 20.8069 25.1655C20.9349 25.654 21.0535 26.145 21.1759 26.6351C21.1831 26.6642 21.1886 26.6941 21.1914 26.724C21.2012 26.8297 21.1623 26.9167 21.0842 26.9849C21.0506 27.0143 21.0124 27.0388 20.9747 27.0629C20.5737 27.319 20.1537 27.5391 19.7241 27.7409C19.6025 27.798 19.4807 27.8547 19.3582 27.9099C19.3176 27.9282 19.2754 27.9444 19.2324 27.9559C19.1699 27.9727 19.1219 27.9484 19.0973 27.8878C19.0832 27.8532 19.0747 27.816 19.0665 27.7793C18.9659 27.3268 18.8658 26.8741 18.7655 26.4215C18.7614 26.4031 18.7557 26.3851 18.7491 26.3577Z" fill="#6366F1"/>
      <path d="M20.894 6.64078C20.5848 6.93697 20.279 7.22517 19.9785 7.51884C19.7583 7.73394 19.5414 7.95264 19.3296 8.17618C19.056 8.46504 18.7893 8.76059 18.5188 9.05248C18.4833 9.09078 18.4462 9.12923 18.404 9.15915C18.3454 9.20074 18.2943 9.19679 18.241 9.1473C18.2029 9.11196 18.1701 9.06974 18.1397 9.02713C17.9059 8.69968 17.6738 8.37091 17.4398 8.04356C17.3436 7.90894 17.2437 7.777 17.1461 7.64343C17.1239 7.61313 17.1029 7.58187 17.0833 7.54977C17.0288 7.46001 17.0301 7.37126 17.0923 7.28549C17.1121 7.25816 17.1324 7.23083 17.1554 7.20624C17.4604 6.87974 17.7626 6.55054 18.0721 6.22848C18.374 5.91439 18.682 5.60634 18.9899 5.29829C19.1969 5.09122 19.4077 4.88803 19.6185 4.68493C19.6559 4.64891 19.6995 4.61812 19.7442 4.59165C19.8014 4.55771 19.8612 4.55012 19.9249 4.58471C20.0627 4.6596 20.2046 4.72688 20.3412 4.80386C20.7847 5.0538 21.2091 5.33478 21.6289 5.62295C21.6625 5.64599 21.6947 5.67317 21.7213 5.70397C21.7629 5.75222 21.7654 5.80765 21.7285 5.85974C21.7028 5.89612 21.6717 5.92955 21.6389 5.95971C21.3931 6.1855 21.1462 6.41014 20.894 6.64078Z" fill="#6366F1"/>
      <path d="M3.76448 15.2639C4.16376 15.3636 4.5533 15.4647 4.9445 15.5586C5.24899 15.6317 5.55572 15.6952 5.86103 15.7649C5.90771 15.7756 5.95369 15.7931 5.99712 15.8137C6.05448 15.8409 6.07862 15.8923 6.06676 15.9541C6.05761 16.0017 6.04177 16.0491 6.0218 16.0933C5.95829 16.2339 5.8885 16.3717 5.82646 16.5129C5.65395 16.9057 5.4843 17.2997 5.313 17.693C5.29502 17.7343 5.27677 17.7761 5.25312 17.8141C5.19575 17.9063 5.11078 17.9463 5.00487 17.9284C4.90949 17.9123 4.81475 17.8911 4.72069 17.8682C3.71413 17.6226 2.71499 17.349 1.72155 17.0538C1.67155 17.039 1.62136 17.024 1.57287 17.0049C1.42291 16.9459 1.3411 16.8303 1.32225 16.672C1.3071 16.5448 1.29689 16.4165 1.29347 16.2885C1.28468 15.9598 1.30994 15.6324 1.33565 15.3049C1.34686 15.1621 1.34543 15.0182 1.35113 14.8749C1.35308 14.8261 1.35492 14.7765 1.3644 14.7288C1.38627 14.6187 1.46312 14.5722 1.57112 14.6047C1.7282 14.652 1.88449 14.702 2.04045 14.753C2.59699 14.9348 3.15838 15.0997 3.72337 15.2523C3.73416 15.2552 3.74476 15.2589 3.76448 15.2639Z" fill="#6366F1"/>
      <path d="M2.43086 10.7637C2.91678 9.69245 3.53779 8.71901 4.28692 7.82877C4.37155 7.7282 4.45844 7.62957 4.54495 7.53064C4.56214 7.51098 4.5813 7.49272 4.60148 7.47624C4.62022 7.46092 4.64195 7.46276 4.66685 7.47005C4.66946 7.50771 4.65927 7.53971 4.65116 7.57144C4.51597 8.1008 4.37723 8.62924 4.24567 9.15952C4.12223 9.6571 4.01578 10.1587 3.92261 10.663C3.86026 11.0006 3.80007 11.3387 3.74722 11.6779C3.70078 11.9759 3.66223 12.2753 3.62689 12.575C3.58847 12.9008 3.5576 13.2276 3.52381 13.554C3.51409 13.6478 3.5095 13.7423 3.49572 13.8355C3.47352 13.9854 3.4209 14.0222 3.27265 13.9913C3.19262 13.9746 3.11399 13.9502 3.03565 13.9262C2.63292 13.8027 2.23038 13.6786 1.82809 13.5536C1.78561 13.5404 1.74293 13.5245 1.70434 13.5026C1.62647 13.4585 1.58323 13.3903 1.59313 13.2974C1.5991 13.2414 1.61045 13.1857 1.62353 13.1309C1.81736 12.3186 2.08433 11.5317 2.43086 10.7637Z" fill="#6366F1"/>
      <path d="M13.5547 29.0537C13.3618 29.04 13.1793 29.03 12.9977 29.0116C12.7568 28.9872 12.5158 28.9607 12.2761 28.9266C12.0401 28.893 11.8054 28.8497 11.5705 28.8084C11.4864 28.7935 11.4045 28.7687 11.3239 28.7369C11.3442 28.7006 11.3799 28.7058 11.4075 28.6981C11.9281 28.5531 12.4493 28.4103 12.9702 28.2661C13.7287 28.0562 14.475 27.8082 15.211 27.5285C15.9193 27.2594 16.6156 26.9614 17.3029 26.6416C17.3704 26.6102 17.4394 26.5809 17.51 26.5578C17.615 26.5236 17.6602 26.5459 17.7001 26.6524C17.7263 26.7226 17.7464 26.7957 17.7634 26.8689C17.8385 27.1919 17.911 27.5155 17.9854 27.8387C18.0091 27.9415 18.0359 28.0437 18.0607 28.1463C18.1046 28.3282 18.0649 28.4061 17.8868 28.4618C17.6984 28.5207 17.5079 28.5734 17.3174 28.6254C16.9647 28.7215 16.6082 28.8017 16.2487 28.8664C16.0287 28.906 15.8071 28.9365 15.5858 28.9682C15.2904 29.0106 14.9932 29.0377 14.6952 29.0482C14.4121 29.0583 14.1285 29.0574 13.8451 29.0597C13.752 29.0604 13.6587 29.0559 13.5547 29.0537Z" fill="#6366F1"/>
      <path d="M25.908 11.8494C25.7484 11.6897 25.5934 11.5367 25.4405 11.3817C24.5193 10.4476 23.5457 9.5737 22.5069 8.77569C22.1771 8.52238 21.8382 8.28135 21.504 8.03386C21.45 7.99385 21.395 7.9537 21.3478 7.90609C21.2685 7.82604 21.2669 7.75281 21.3373 7.66532C21.3654 7.63039 21.3977 7.59809 21.4319 7.56921C21.7672 7.28612 22.0879 6.98645 22.4101 6.68844C22.4404 6.66044 22.4706 6.63204 22.5037 6.60762C22.5985 6.53747 22.6659 6.53683 22.7574 6.60956C22.8101 6.65139 22.8581 6.69933 22.9067 6.74619C23.38 7.20304 23.8209 7.68978 24.2244 8.21104C24.6973 8.82187 25.1125 9.4703 25.4672 10.1584C25.7852 10.7752 26.0502 11.4144 26.2647 12.0751C26.274 12.1038 26.2849 12.1321 26.2915 12.1614C26.2942 12.1733 26.2872 12.1875 26.2846 12.2005C26.2711 12.1982 26.2618 12.1996 26.2577 12.1956C26.1427 12.0824 26.0282 11.9687 25.908 11.8494Z" fill="#6366F1"/>
      <path d="M16.2124 3.35767C16.2887 3.33973 16.3557 3.34743 16.4215 3.36097C17.1377 3.50835 17.8427 3.69565 18.5244 3.96601C18.5623 3.98104 18.6001 3.99783 18.6348 4.01905C18.667 4.03873 18.6722 4.07627 18.6515 4.10812C18.6414 4.12371 18.6284 4.13774 18.6152 4.15092C18.4666 4.2994 18.3174 4.44728 18.169 4.59594C17.9809 4.78437 17.7923 4.97245 17.6057 5.16246C17.4638 5.30697 17.3235 5.4532 17.1845 5.60054C16.9604 5.83788 16.7381 6.0769 16.5148 6.31488C16.4788 6.35316 16.4434 6.39238 16.4038 6.42652C16.3402 6.48135 16.2882 6.47997 16.2243 6.42427C16.1991 6.40228 16.1767 6.3764 16.1556 6.35019C15.7856 5.89086 15.4054 5.44033 15.0189 4.99521C14.9846 4.95572 14.9514 4.91418 14.9246 4.86937C14.8825 4.79901 14.8834 4.72476 14.9279 4.65509C14.95 4.62052 14.9763 4.58789 15.0047 4.55838C15.1999 4.35565 15.3964 4.15428 15.5919 3.95186C15.7457 3.79264 15.8992 3.63314 16.0515 3.47247C16.096 3.42558 16.1424 3.3833 16.2124 3.35767Z" fill="#6366F1"/>
      <path d="M23.6078 22.8446C23.7387 23.2911 23.8662 23.7291 23.9979 24.1658C24.0255 24.2576 24.0128 24.3367 23.9599 24.412C23.9362 24.4458 23.9098 24.4778 23.8831 24.5093C23.6689 24.7615 23.4441 25.0035 23.2081 25.2352C22.9347 25.5036 22.6508 25.7601 22.3603 26.0092C22.3237 26.0406 22.2835 26.0694 22.2409 26.0918C22.1871 26.1201 22.145 26.1073 22.1192 26.0535C22.0967 26.0065 22.0811 25.9553 22.0686 25.9044C21.9976 25.6149 21.9344 25.3234 21.8575 25.0355C21.7037 24.46 21.5431 23.8864 21.3846 23.3121C21.3565 23.2104 21.3239 23.1101 21.2956 23.0084C21.2564 22.8679 21.2839 22.8253 21.4277 22.7985C21.5927 22.7676 21.7577 22.7375 21.9226 22.7063C22.3366 22.6279 22.7505 22.5491 23.1644 22.4704C23.2047 22.4628 23.245 22.4549 23.2853 22.4474C23.408 22.4244 23.4866 22.4682 23.5297 22.5872C23.5502 22.6438 23.5653 22.7024 23.5828 22.7601C23.5905 22.7853 23.5983 22.8105 23.6078 22.8446Z" fill="#6366F1"/>
      <path d="M4.06155 21.0822C4.0148 21.1346 3.96194 21.149 3.90375 21.1441C3.86686 21.141 3.82975 21.1351 3.79386 21.126C3.31368 21.0033 2.83366 20.88 2.35375 20.7562C2.31785 20.7469 2.28165 20.7362 2.24814 20.7205C2.17786 20.6877 2.12582 20.6357 2.09977 20.5598C2.07654 20.4921 2.05196 20.4249 2.02896 20.3571C1.86065 19.8609 1.7108 19.3594 1.60235 18.846C1.56729 18.6799 1.53727 18.5128 1.50597 18.346C1.49972 18.3128 1.49577 18.2786 1.49493 18.2448C1.49331 18.1796 1.52924 18.1448 1.59201 18.1536C1.63233 18.1592 1.67182 18.1722 1.71114 18.1837C1.97918 18.2626 2.24698 18.3423 2.51503 18.4211C3.19099 18.6198 3.87274 18.796 4.55738 18.9611C4.5899 18.9689 4.62329 18.9762 4.65352 18.99C4.7227 19.0215 4.75034 19.08 4.73393 19.1563C4.72604 19.193 4.71481 19.2292 4.70265 19.2648C4.51628 19.81 4.32993 20.3552 4.14222 20.8999C4.12152 20.96 4.09252 21.0171 4.06155 21.0822Z" fill="#6366F1"/>
      </g>
      <defs>
      <clipPath id="clip0_102_4">
      <rect width="28" height="32" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  );
};
