import React, { useState } from 'react';
import { Layout } from '../layouts/auth/layout';
import Logo from '../assets/stfi-logo.png';
import { Box, Typography } from '@mui/material';
import { ERROR_STR, STORAGE, snackbarProps } from '../constant';
import { API_STFI } from '../api/stfi';
import { useSearchParams } from 'react-router-dom';
import GoogleAuth from '../Auth/GoogleAuth';

function ReLogin() {

  const [searchParams] = useSearchParams();
  const gmail = searchParams.get("gmail");

  const [snackbar, setSnackbar] = useState(snackbarProps.init());
  snackbar.hide = () => {
    setSnackbar(snackbarProps.hide());
  };

  const [backdrop, setBackdrop] = useState(true);

  const GoogleLoginCb = async (googleResp) => {

    if(!googleResp || (googleResp && !googleResp.credential)) {
      // Google login api failed
      setSnackbar(snackbarProps.error(ERROR_STR.GOOGLE_LOGIN_ERROR));
      return;
    }

    // Show loader
    setBackdrop(true);

    try {

      // Authenticate the google token
      const response = await API_STFI.LOGIN(googleResp.credential);

      // Hide loader
      setBackdrop(false);

      // Google user meta data and stfi user info is store in local storage
      // This will be used when page reloaded or any need in the child components
      const info = {...JSON.parse(atob(googleResp.credential.split('.')[1])), ...response.data};
      localStorage.setItem(STORAGE.USERINFO, btoa(JSON.stringify(info)));

      window.opener.postMessage('{login:true}', '*');

      
    } catch (error) {

      setBackdrop(false);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('Server responded with an error:', error.response.status, error.response);
        setSnackbar(snackbarProps.error(error.response.statusText));
      } else if (error.request) {
        // The request was made but no response was received
        console.error('No response received from the server');
        setSnackbar(snackbarProps.error(ERROR_STR.NO_RESPONSE));
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error setting up the request:', error.message);
        setSnackbar(snackbarProps.error(ERROR_STR.STD));
      }
    }
  };
    
  return (
    <Layout snackbar = {snackbar} backdrop = {backdrop} > 
      <Box 
        component={"img"} 
        sx = {{
          width:"200px"
        }}  
        src = {Logo} 
      >
      </Box>
      <Typography 
        sx = {{
          fontWeight:"500",
          margin:"20px 0",
          fontSize:{xl:"40px", md:"30px", sm:"25px", xs:"20px"},
          color:"white"
        }}
      >
        SepakTakraw Federation Of India
      </Typography>
      <Typography 
        sx = {{
          margin:"0px 0",
          opacity:"0.8",
          fontSize:{xl:"20px", md:"20px", sm:"15px", xs:"12px"},
          color:"white",
          marginBottom:"30px"
        }}
      >
        Recognized by the Ministry of Youth Affairs and Sports
      </Typography>
      <GoogleAuth 
        GoogleLoginCb = {GoogleLoginCb} 
        auto_select = {true}
        login_hint = {gmail}
      />
    </Layout>
  )
}

export default ReLogin