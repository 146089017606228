import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { ERROR_STR, USER_ROLE, dialogButtonsProps, dialogProps, infoProps, snackbarProps } from '../../constant';
import { API_STFI } from '../../api/stfi';
import Info from '../../components/Info';
import Snackbar from '../../components/Snackbar';
import Backdrop from '../../components/Backdrop';
import Dialog from '../../components/Dialog';
import { Link } from 'react-router-dom';

function applyPagination(documents, page, rowsPerPage) {
  return documents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}

const useCustomers = (data, page, rowsPerPage) => {
  return useMemo(
    () => {
      return applyPagination(data, page, rowsPerPage);
    },
    [data, page, rowsPerPage]
  );
};

const UsersView = () => {

  const [loading, setLoading] = useState(true);

  const [backdrop, setBackdrop] = useState(false);

  const [dialog, setDialog] = useState(dialogProps.init());

  const [snackbar, setSnackbar] = useState(snackbarProps.init());
  snackbar.hide = () => {
    setSnackbar(snackbarProps.hide());
  };

  const [info, setInfo] = useState(infoProps.init());

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const tableData = useCustomers(data, page, rowsPerPage);

  const handlePageChange = useCallback(
    (event, value) => {
      setPage(value);
    },
    []
  );

  const handleRowsPerPageChange = useCallback(
    (event) => {
      setRowsPerPage(event.target.value);
    },
    []
  );

  const fetchData = async () => {
    try {

      // Authenticate the google token
      const response = await API_STFI.USERS();

      setData(response.data);

      // Hide loader
      setLoading(false);

      setInfo(infoProps.close());

    } catch (error) {

      // Hide loader
      setLoading(false);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('Server responded with an error:', error.response.status, error.response);
        setInfo(infoProps.error(error.response.statusText));
      } else if (error.request) {
        // The request was made but no response was received
        console.error('No response received from the server');
        setInfo(infoProps.error(ERROR_STR.NO_RESPONSE));
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error setting up the request:', error.message);
        setInfo(infoProps.error(ERROR_STR.STD));
      }
    }
  }

  const onDelete = async (email) => {
  
    var buttons = [];
    buttons.push(dialogButtonsProps.init("Revoke", async () => {

      setDialog(dialogProps.close());
      setBackdrop(true);

        try {

          // Authenticate the google token
          const response = await API_STFI.USERS_DELETE(email);
          console.log(response);

          // Hide loader
          setBackdrop(false);

          fetchData();

        } catch (error) {

          // Hide loader
          setBackdrop(false);
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with an error:', error.response.status, error.response);
            setSnackbar(snackbarProps.error(error.response.statusText));
          } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from the server');
            setSnackbar(snackbarProps.error(ERROR_STR.NO_RESPONSE));
          } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error setting up the request:', error.message);
            setSnackbar(snackbarProps.error(ERROR_STR.STD));
          }
        }
    }));
    buttons.push(dialogButtonsProps.init("Close", () => {
      setDialog(dialogProps.close());
    }));

    setDialog(dialogProps.info("Revoke", `Do you want to revoke ${email} access?`, buttons));
  };

  useEffect(() => {

    fetchData();
    
  }, []);


  return (
    <>
      {
        (loading) ?
        <Box sx={{ width: '100%', height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CircularProgress />
        </Box>
        :
        (data.length)?
        <Card sx={{overflowX:"auto" }}>
          <Box sx={{ minWidth: 800}}>
              <Table>
              <TableHead>
                  <TableRow>
                  <TableCell>
                      Name
                  </TableCell>
                  <TableCell>
                      Gmail
                  </TableCell>
                  <TableCell>
                      State
                  </TableCell>
                  <TableCell>
                      Role
                  </TableCell>
                  <TableCell>
                      Action
                  </TableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
                  {tableData.map((data, i) => {

                  return (
                      <TableRow
                      hover
                      key={i}
                      >
                      <TableCell>
                        {data.name}
                      </TableCell>
                      <TableCell>
                          {data.email}
                      </TableCell>
                      <TableCell>
                          {data.stateName}
                      </TableCell>
                      <TableCell>
                        {(data.role === USER_ROLE.ADMIN)?"Admin":(data.role === USER_ROLE.STATE_ADMIN)?"State Admin":"Guest"}
                      </TableCell>
                      <TableCell>
                        <Button
                          component={Link}
                          to={"/users?action=edit&email="+data.email}
                        >
                          Edit
                        </Button>
                        <Button onClick={() => onDelete(data.email)}>Revoke</Button>
                      </TableCell>
                      </TableRow>
                  );
                  })}
              </TableBody>
              </Table>
          </Box>
        <TablePagination
          component="div"
          count={data.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>
        :
        <Typography></Typography>
      }
      <Info {...info} />
      <Snackbar {...snackbar} />
      <Backdrop open = {backdrop}/>
      <Dialog {...dialog} /> 
    </>
  )
}

export default UsersView