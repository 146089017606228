import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Card, Button,CardContent, CardHeader, Unstable_Grid2 as Grid, TextField } from '@mui/material';
import { HELPER_TEXT, REG_FORM_KEY, REG_FORM_LABEL, RULE_PATTERN_VALUE } from '../../constant';

export const RegistrationForm1 = () => {


  const [searchParams, setSearchParams] = useSearchParams();
let updatedSearchParams = new URLSearchParams(searchParams.toString());

  var form1fields = {};
    form1fields[REG_FORM_KEY.AADHAAR_CARD_NUMBER] = "";
    form1fields[REG_FORM_KEY.EMAIL] = "";

  const [values, setValues] = useState(form1fields);

  const [errors] = useState({});

  const handleChange = useCallback(
    (event) => {

      const { name, value } = event.target;

      setValues((prevState) => ({
        ...prevState,
        [name]: value
      }));
    },
    [setValues]
  );

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      const email = event.target.elements[REG_FORM_KEY.EMAIL].value;
      const aadhaarCardNumber = event.target.elements[REG_FORM_KEY.AADHAAR_CARD_NUMBER].value;

      const formData = new FormData();
      formData.append(REG_FORM_KEY.EMAIL, email);
      formData.append(REG_FORM_KEY.AADHAAR_CARD_NUMBER, aadhaarCardNumber);

      updatedSearchParams.set("formtype", 2);
      updatedSearchParams.set(REG_FORM_KEY.EMAIL, email);
      updatedSearchParams.set(REG_FORM_KEY.AADHAAR_CARD_NUMBER, aadhaarCardNumber);
        setSearchParams(updatedSearchParams.toString());
    },
    []
  );

  return (
    <form
      onSubmit={handleSubmit}
    >
      <Card sx = {{height:"100%"}}>
        <CardHeader
          subheader = {"Provide the following details to proceed with the registration"}
          title = {"Basic Details"}
        />
        <CardContent sx={{ pt: 0 }}>
            <Box sx={{ m: -1.5 }}>
                <Grid
                  container
                  spacing={3}
                >
                  {
                    (values[REG_FORM_KEY.EMAIL] !== undefined)?
                    <Grid
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label={REG_FORM_LABEL.EMAIL}
                        name={REG_FORM_KEY.EMAIL}
                        required
                        onChange={handleChange}
                        value={values[REG_FORM_KEY.EMAIL]}
                        error={errors[REG_FORM_KEY.EMAIL]}
                        helperText={errors[REG_FORM_KEY.EMAIL] ? HELPER_TEXT.GMAIL : ''}
                        inputProps={{ pattern: RULE_PATTERN_VALUE.GMAIL }}
                      />
                    </Grid>
                    :
                    <></>
                  }
                  {
                    (values[REG_FORM_KEY.AADHAAR_CARD_NUMBER] !== undefined)?
                    <Grid
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label={REG_FORM_LABEL.AADHAAR_CARD_NUMBER}
                        name={REG_FORM_KEY.AADHAAR_CARD_NUMBER}
                        required
                        onChange={handleChange}
                        value={values[REG_FORM_KEY.AADHAAR_CARD_NUMBER]}
                        error={errors[REG_FORM_KEY.AADHAAR_CARD_NUMBER]}
                        helperText={errors[REG_FORM_KEY.AADHAAR_CARD_NUMBER] ? HELPER_TEXT.AADHAAR_CARD_NUMBER : ''}
                        inputProps={{ pattern: RULE_PATTERN_VALUE.AADHAAR_CARD_NUMBER }}
                      />
                    </Grid>
                    :
                    <></>
                  }
                </Grid>
            </Box>
        </CardContent>
    </Card>
    <Box sx={{ mt: 5, textAlign: "center" }}>
        <Button type='submit' variant='contained'>Proceed</Button>
    </Box>
    </form>
  );
};
