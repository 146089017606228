import React, { useEffect, useState } from 'react'
import Dialog from '../../components/Dialog';
import Backdrop from '../../components/Backdrop';
import Snackbar from '../../components/Snackbar';
import Info from '../../components/Info';
import { Box, CircularProgress, Unstable_Grid2 as Grid} from '@mui/material';
import { ERROR_STR, dialogButtonsProps, dialogProps, infoProps, snackbarProps } from '../../constant';
import { API_STFI } from '../../api/stfi';
import { NewsFeedCard } from '../../components/NewsFeedCard';

const NewsFeedView = (props) => {

    const {user} = props;

    const [loading, setLoading] = useState(true);

    const [backdrop, setBackdrop] = useState(false);
  
    const [dialog, setDialog] = useState(dialogProps.init());
  
    const [snackbar, setSnackbar] = useState(snackbarProps.init());
    snackbar.hide = () => {
      setSnackbar(snackbarProps.hide());
    };
  
    const [info, setInfo] = useState(infoProps.init());
  
    const [data, setData] = useState([]);

    const onDelete = async (id) => {
  
      var buttons = [];
      buttons.push(dialogButtonsProps.init("Delete", async () => {
  
        setDialog(dialogProps.close());
        setBackdrop(true);
  
          try {
  
            // Authenticate the google token
            const response = await API_STFI.NEWS_FEED_DELETE(id);
            console.log(response);
  
            // Hide loader
            setBackdrop(false);
  
            fetchData();
  
          } catch (error) {
  
            // Hide loader
            setBackdrop(false);
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.error('Server responded with an error:', error.response.status, error.response);
              setSnackbar(snackbarProps.error(error.response.statusText));
            } else if (error.request) {
              // The request was made but no response was received
              console.error('No response received from the server');
              setSnackbar(snackbarProps.error(ERROR_STR.NO_RESPONSE));
            } else {
              // Something happened in setting up the request that triggered an Error
              console.error('Error setting up the request:', error.message);
              setSnackbar(snackbarProps.error(ERROR_STR.STD));
            }
          }
      }));
      buttons.push(dialogButtonsProps.init("Close", () => {
        setDialog(dialogProps.close());
      }));
  
      setDialog(dialogProps.info("Delete", "Do you want to delete?", buttons));
    };

    const fetchData = async () => {
      try {

        // Authenticate the google token
        const response = await API_STFI.NEWS_FEED();
  
        setData(response.data);
  
        // Hide loader
        setLoading(false);
  
        setInfo(infoProps.close());
  
      } catch (error) {
  
        // Hide loader
        setLoading(false);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error('Server responded with an error:', error.response.status, error.response);
          setInfo(infoProps.error(error.response.statusText));
        } else if (error.request) {
          // The request was made but no response was received
          console.error('No response received from the server');
          setInfo(infoProps.error(ERROR_STR.NO_RESPONSE));
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Error setting up the request:', error.message);
          setInfo(infoProps.error(ERROR_STR.STD));
        }
      }
    }
  
    useEffect(() => {
  
      fetchData();
      
    }, []);

    return (
      <>
        {
          (loading) ?
          <Box sx={{ width: '100%', height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress />
          </Box>
          :
          <Grid
            container
            spacing={3}
          >
          {
            data.map((value) => (
            <Grid
              xs={12}
              md={6}
              lg={4}
              key={value.id}
            >
              <NewsFeedCard data={value} user = {user} onDelete = {onDelete} />
            </Grid>
            ))
          }
          <Info {...info} />
          <Snackbar {...snackbar} />
          <Backdrop open = {backdrop}/>
          <Dialog {...dialog} /> 
          </Grid>
        }
      </>
    );
}

export default NewsFeedView