import { Box, Button, Card, CardContent, CardHeader, Unstable_Grid2 as Grid, TextField } from '@mui/material'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { ERROR_STR, HELPER_TEXT, RULE_PATTERN_VALUE, STATES_FORM_KEY, snackbarProps } from '../../constant';
import Backdrop from '../../components/Backdrop';
import Snackbar from '../../components/Snackbar';
import { API_STFI } from '../../api/stfi';

const StatesAdd = (props) => {

  const {action} = props;

  const formRef = useRef(null);

  const initState = useMemo(() => ({
    "stateCode": "",
    "stateName": ""
  }), []);

  const [values, setValues] = useState(initState);

  const [backdrop, setBackdrop] = useState(false);
  
  const [snackbar, setSnackbar] = useState(snackbarProps.init());
  snackbar.hide = () => {
    setSnackbar(snackbarProps.hide());
  };

  const [errors] = useState({});

  const handleChange = useCallback(
    (event) => {

      const { name, value } = event.target;

      setValues((prevState) => ({
        ...prevState,
        [name]: value
      }));
    },
    [setValues]
  );

  const submitData = useCallback(async (formData) => {
    try {
      // Authenticate the google token
      await API_STFI.STATES_ADD(formData);
  
      // Hide loader
      setBackdrop(false);
  
      setSnackbar(snackbarProps.success("Form submitted successfully"));
  
      if (action === "add") {
        formRef.current.reset();
        setValues(initState);
      }
    } catch (error) {
      // Hide loader
      setBackdrop(false);
      if (error.response) {
        console.error('Server responded with an error:', error.response.status, error.response);
        setSnackbar(snackbarProps.error(error.response.statusText));
      } else if (error.request) {
        console.error('No response received from the server');
        setSnackbar(snackbarProps.error(ERROR_STR.NO_RESPONSE));
      } else {
        console.error('Error setting up the request:', error.message);
        setSnackbar(snackbarProps.error(ERROR_STR.STD));
      }
    }
  }, [action, setBackdrop, setSnackbar, setValues, formRef, initState]);
  
  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
  
      const data = {};
      const formElements = event.target.elements;
  
      for (let i = 0; i < formElements.length; i++) {
        const element = formElements[i];
  
        if (element.name) {
          data[element.name] = element.value;
        }
      }
  
      setBackdrop(true);
      submitData(data);
    },
    [submitData]
  );
  
  return (
   
    <form
      onSubmit={handleSubmit}
      ref={formRef}
    >
      <Card sx = {{height:"100%"}}>
        <CardHeader
          subheader = {"Enter the details to add a new state"}
          title = {"Add State"}
        />
        <CardContent sx={{ pt: 0 }}>
            <Box sx={{ m: -1.5 }}>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    xs={12}
                  >
                      <TextField
                        fullWidth
                        label={"State Name"}
                        name={STATES_FORM_KEY.STATE_NAME}
                        required
                        onChange={handleChange}
                        value={values[STATES_FORM_KEY.STATE_NAME]}
                        error={errors[STATES_FORM_KEY.STATE_NAME]}
                        helperText={HELPER_TEXT.ONLY_ALPABETS_MAX_128}
                        inputProps={{ pattern: RULE_PATTERN_VALUE.ONLY_ALPABETS_MAX_128 }}
                      />
                  </Grid>
                  <Grid
                    xs={12}
                  >
                      <TextField
                        fullWidth
                        label={"State Code"}
                        name={STATES_FORM_KEY.STATE_CODE}
                        required
                        onChange={handleChange}
                        value={values[STATES_FORM_KEY.STATE_CODE]}
                        error={errors[STATES_FORM_KEY.STATE_CODE]}
                        helperText={HELPER_TEXT.STATE_CODE}
                        inputProps={{ pattern: RULE_PATTERN_VALUE.STATE_CODE }}
                      />
                  </Grid>
                </Grid>
            </Box>
        </CardContent>
    </Card>
      <Box sx={{ mt: 5, textAlign: "center" }}>
				<Button type='submit' variant='contained'>Proceed</Button>
			</Box>
      <Snackbar {...snackbar} />
      <Backdrop open = {backdrop}/>
    </form>
  )
}

export default StatesAdd