import * as React from 'react';
import Button from '@mui/material/Button';
import { Dialog as DialogMui } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function Dialog({open, header, message, buttons}) {
    
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    (open)?
      <DialogMui
        fullScreen={fullScreen}
        open={true}
        onClose={() => {}}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {header}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        {
          buttons.map((value, index) => {
            return (
              <Button key = {index} onClick={() => { value.onClick() }}>{value.label}</Button>
            )
          })
        }
        </DialogActions>
      </DialogMui>
      :
      <></>
  );
}