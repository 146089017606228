import React from 'react'
import { Snackbar as SnackbarMui } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

function Snackbar(props) {

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <SnackbarMui open={props.open} autoHideDuration={props.duration} onClose={() => {props.hide(); if(props.onClose)props.onClose();}} anchorOrigin={props.anchorOrigin} >
        <Alert onClose={props.hide} severity={props.severity} sx={{ width: '100%', color:"white" }} >
            {props.message}
        </Alert>
    </SnackbarMui>
  )
}

export default Snackbar