export const GOOGLE_MEASUREMENT_ID = 'G-MTC1HGT4SH';

export const snackbarProps = {
    init:() => {
        let obj = {};
        obj.open = false;
        obj.severity = '';
        obj.message = '';
        obj.duration = 5000;
        obj.anchorOrigin = {vertical: 'top', horizontal: 'right'};
        return obj;
    },
    success:(message, onClose) => {
        let obj = {};
        obj.open = true;
        obj.severity = 'success';
        obj.message = message;
        obj.duration = 5000;
        obj.anchorOrigin = {vertical: 'top', horizontal: 'right'};
        obj.onClose = onClose;
        return obj;
    },
    error:(message) => {
        let obj = {};
        obj.open = true;
        obj.severity = 'error';
        obj.message = message;
        obj.duration = undefined;
        obj.anchorOrigin = {vertical: 'top', horizontal: 'center'};
        return obj;
    },
    hide:() => {
        let obj = {};
        obj.open = false;
        return obj;
    }
};

export const infoProps = {
    init:() => {
        let obj = {};
        obj.open = false;
        obj.text = '';
        return obj;
    },
    error:(message, back) => {
        let obj = {};
        obj.open = true;
        obj.text = message;
        obj.back= back;
        return obj;
    },
    close:() => {
        let obj = {};
        obj.open = false;
        obj.text = '';
        return obj;
    },
};

export const dialogButtonsProps = {
    init:(label, onClick)=> {
        let obj = {};
        obj.label = label;
        obj.onClick = onClick;
        return obj;
    }
};

export const dialogProps = {
    init:() => {
        let obj = {};
        obj.open = false;
        obj.header = '';
        obj.message = '';
        obj.buttons = [];
        return obj;
    },
    info:(header, message, buttons) => {
        let obj = {};
        obj.open = true;
        obj.header = header;
        obj.message = message;
        obj.buttons = buttons;
        return obj;
    },
    close:() => {
        let obj = {};
        obj.open = false;
        obj.header = '';
        obj.message = '';
        obj.buttons = [];
        return obj;
    }
};

export const ERROR_STR = {
    GOOGLE_LOGIN_ERROR:"Google login error. Please try again after some time",
    STD:"We are unable to process your request. Please try after some time.",
    NO_RESPONSE:"No response received from the server"
};

export const USER_ROLE = {
    ADMIN:0,
    STATE_ADMIN:1,
    GUEST:2,
};

export const STORAGE = {
    USERINFO:'ye',
};

export const NEWSFEED_FORM_KEY = {
    HEADING:"heading",
    CONTENT:"content",
    Date:"date",
    IMAGE:"image",
    FILE:"file"
};

export const GALLERY_FORM_KEY= {
    IMAGE:"file"
};

export const USER_FORM_KEY = {
    NAME:"name",
    ROLE:"role",
    STATECODE:"stateCode",
    STATENAME:"stateName",
    EMAIL:"email",
};

export const RULE_PATTERN_VALUE = {
    PHONE_NUMBER:"^[0-9]{10}$",
    ONLY_ALPABETS_MAX_128:"^[a-zA-Z ]{0,128}$",
    ONLY_NUMBER_MAX_4:"^[0-9]{0,5}$",
    PASSPORT_NUMBER:"^[A-Z]{1}[0-9]{7}$",
    PASSPORT_NUMBER_OLD:"^[A-PR-WY][1-9]\\d\\s?\\d{4}[1-9]$",
    DD_MM_YYYY:"^([0-2][0-9]|3[0-1])-(0[1-9]|1[0-2])-\\d{4}$",
    GMAIL:"[a-z0-9](.?[a-z0-9]){5,}@gmail.com$",
    STATE_CODE: "[a-zA-Z]{2,3}$",
    FOUR_NUMBERS:"^[0-9]{4}$",
    AADHAAR_CARD_NUMBER:"^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$"
};

export const FILE_MAX_SIZE = 1024000;
export const HELPER_TEXT = {
    DATE:"DD-MM-YYYY",
    IMAGE:"Supported : JGEP, JPG and PNG. Maximum size : 1 MB",
    AADHAAR_XML:"Supported: ZIP",
    GOVERNMENT_PROOF:"Voter ID or PAN card or Driving license or Birth certifcate. Supported : JGEP, JPG and PNG. Maximum size : 1 MB",
    SHARE_CODE:"4-Digit code",
    PHONE_NUMBER:"10 digit number",
    ONLY_ALPABETS_MAX_128:"Alphabets only, max 128 characters",
    MAX_64:"Maximum 64 characters",
    MAX_128:"Maximum 128 characters",
    MAX_256:"Maximum 256 characters",
    ONLY_NUMBER_MAX_4:"Numbers only",
    AADHAAR_CARD_NUMBER:"Provide a valid Aadaar card number",
    GMAIL:"Enter a valid gmail address",
    ONLY_PDF:"Supported: PDF. Maximum size : 1 MB",
    STATE_CODE:"Only alphabets, minimum 2 and maximum 3 characters are allowed"
};

export const ACCEPT_FILE_TYPE = {
    IMAGE:"image/png, image/jpeg, image/jpg",
    XML:"application/xml",
    ZIP:"application/zip",
    PDF:"application/pdf"
};

export const STATES_FORM_KEY = {
    STATE_NAME:"stateName",
    STATE_CODE:"stateCode",
};

export const REG_FORM_KEY = {
    ROLE:"role",
    AADHAAR_CARD_NUMBER:"aadhaarCardNumber",
    EMAIL:"emailId",
    PHONE_NUMBER:"phoneNumber",
    FATHER_NAME:"fatherName",
    MOTHER_NAME:"motherName",
    NATIONAL_PLAYED:"numberOfNationalPlayed",
    INTERNATIONAL_PLAYED:"numberOfInternationalPlayed",
    NATIONAL_PARTICIPATED_AS_COACH:"numberOfNationalParticipatedAsCoach",
    NATIONAL_PARTICIPATED_AS_MANAGER:"numberOfNationalParticipatedAsManager",
    INTERNATIONAL_PARTICIPATED_AS_COACH:"numberOfInternationalParticipatedAsCoach",
    INTERNATIONAL_PARTICIPATED_AS_MANAGER:"numberOfInternationalParticipatedAsManager",
    NATIONAL_REFEREED:"numberOfNationalParticipatedAsReferee",
    INTERNATIONAL_REFEREED:"numberOfInternationalParticipatedAsReferee",
    REFREEE_LEVEL:"refereeLevel",
    REFREEE_PROOF:"refereeCertificate",
    PASSPORT_NUMBER:"passportNumber",
    PASSPORT_EXPIRY_MONTH_AND_YEAR:"passportExpiryYearAndMonth",
    PASSPORT_ISSUE_PLACE:"passportIssuePlace",
    PASSPORT_COPY:"passportProof",
    PASSPORT_SIZE_PHOTO:"passportSizePhoto",
    GOVERNMENT_PROOF:"governmentCertificate",
    AADHAAR_XML:"aadhaarXml",
    NAME:"name",
    SHARE_CODE:"shareCode",
    IS_PLAYER:"playerRegistration",
    IS_COACH_OR_MANAGER:"officialRegistration",
    IS_REFEREE:"refereeRegistration",
    REJECTED_REASON:"rejectedReason",
    DATE_OF_BIRTH:"dateOfBirth",
    GENDER:"gender",
    UPDATED_BY:"updatedBy",
    UPDATED_DATE:"updatedDate",
    PLAYER_REG_ID:"playerRegistrationId",
    PLAYER_UPDATED_BY:"playerUpdatedBy",
    PLAYER_UPDATED_DATE:"playerUpdatedDate",
    OFFICIAL_REG_ID:"officialRegistrationId",
    OFFICIAL_UPDATED_BY:"officialUpdatedBy",
    OFFICIAL_UPDATED_DATE:"officialUpdatedDate",
    REFEREE_REG_ID:"refereeRegistrationId",
    REFEREE_UPDATED_BY:"refereeUpdatedBy",
    REFEREE_UPDATED_DATE:"refereeUpdatedDate",
    PLAYER_REGISTERED_BY:"playerRegisteredBy",
    PLAYER_REGISTERED_DATE:"playerRegisteredDate",
    OFFICIAL_REGISTERED_BY:"officialRegisteredBy",
    OFFICIAL_REGISTERED_DATE:"officialRegisteredDate",
    REFEREE_REGISTERED_BY:"refereeRegisteredBy",
    REFEREE_REGISTERED_DATE:"refereeRegisteredDate",
    PLACE_OF_BIRTH:"placeOfBirth",
    PLAYER_ENROLL_STATE_NAME:"playerEnrolledStateName",
    OFFICIAL_ENROLL_STATE_NAME:"officialEnrolledStateName",
    REFEREE_ENROLL_STATE_NAME:"refereeEnrolledStateName",
    PLAYER_CURRENT_STATUS:"playerCurrentStatus",
    OFFICIAL_CURRENT_STATUS:"officialCurrentStatus",
    REFEREE_CURRENT_STATUS:"refereeCurrentStatus",
};

export const REG_FORM_LABEL = {
    ROLE:"Role",
    AADHAAR_CARD_NUMBER:"Aadhaar Card Number",
    EMAIL:"Gmail",
    PHONE_NUMBER:"Phone Number",
    FATHER_NAME:"Father Name",
    MOTHER_NAME:"Mother Name",
    NATIONAL_PLAYED:"No of National Played",
    INTERNATIONAL_PLAYED:"No of International Played",
    NATIONAL_PARTICIPATED_AS_COACH:"No of National Participated As Coach",
    NATIONAL_PARTICIPATED_AS_MANAGER:"No of National Participated As Manager",
    INTERNATIONAL_PARTICIPATED_AS_COACH:"No of International Participated As Coach",
    INTERNATIONAL_PARTICIPATED_AS_MANAGER:"No of International Participated As Manager",
    NATIONAL_REFEREED:"No of National Refereed",
    INTERNATIONAL_REFEREED:"No of International Refereed",
    REFREEE_LEVEL:"Referee Level",
    REFREEE_PROOF:"Referee Proof",
    PASSPORT_NUMBER:"Passport Number",
    PASSPORT_EXPIRY_MONTH_AND_YEAR:"Passport Expiry Date",
    PASSPORT_ISSUE_PLACE:"Passport Issued Place",
    PASSPORT_COPY:"Passport Copy",
    PASSPORT_SIZE_PHOTO:"Passport Size Photo",
    GOVERNMENT_PROOF:"Government Proof",
    AADHAAR_XML:"Offline Aadhaar ZIP",
    SHARE_CODE:"Share Code",
    DATE_OF_BIRTH:"Date Of Birth",
    GENDER:"Gender",
    UPDATED_BY:"Approved By",
    UPDATED_DATE:"Approved On",
    REGISTERED_BY:"Registered By",
    REGISTERED_DATE:"Registered On",
    NAME:"Name",
    REG_ID:"Registration ID",
    PLACE_OF_BIRTH:"Place of Birth",
    ENROLLED_STATE_NAME:"Enrolled State",
    STATUS:"Status"
};

export const REG_VIEW_STYLE = {
    wrapper:{padding:"6px 1.5em"},
    label:{fontWeight:"normal", color:"grey"},
    value:{color:"black", overflow:"hidden", textOverflow:"ellipsis"}
};


export const RULE_REQUIRED_MESSAGE = {
    AADHAAR_CARD_NUMBER:"Provide aadhaar card number",
    ROLE:"Select a role",
    EMAIL:"Provide a gmail address",
    PHONE_NUMBER:"Provide a phone number",
    FATHER_NAME:"Provide father name",
    MOTHER_NAME:"Provide mother name",
    NO_OF_NATIONAL_PLAYED:"Provide no of national played",
    NO_OF_INTERNATIONAL_PLAYED:"Provide no of international played",
    NATIONAL_PARTICIPATED_AS_COACH:"Provide no of national Participated as coach",
    NATIONAL_PARTICIPATED_AS_MANAGER:"Provide no of national participated as manager",
    INTERNATIONAL_PARTICIPATED_AS_COACH:"Provide no of international participated as coach",
    INTERNATIONAL_PARTICIPATED_AS_MANAGER:"Provide no of international participated as manager",
    NATIONAL_REFEREED:"Provide no of national refereed",
    INTERNATIONAL_REFEREED:"Provide no of international refereed",
    REFREEE_LEVEL:"Select a referee Level",
    REFREEE_PROOF:"Upload a referee proof",
    PASSPORT_NUMBER:"Provide passport number",
    PASSPORT_EXPIRY_MONTH_AND_YEAR:"Provide a passport expiry date",
    PASSPORT_ISSUE_PLACE:"Provide passport issued place",
    PASSPORT_COPY:"Upload Passport Copy",
    PASSPORT_SIZE_PHOTO:"Upload passport Size Photo",
    GOVERNMENT_PROOF:"Upload Government Proof",
    AADHAAR_XML:"Offile Aadhaar ZIP",
    STATE_NAME:"Provide a state name",
    STATE_CODE:"Provide a state code",
    SHARE_CODE:"Prove aadhaar XML share code",
};

export const TERMS_AND_CONDITION_LINK = "https://sepaktakrawindia-registration.com/stfi/adminOrStateAdmin/homePageImage/getTermsAndCondition/STFIRegistrationTermsConditions.pdf";

export const REFREEE_LEVEL_STATE = "S";
export const REFREEE_LEVEL_NATIONAL = "N";
export const REFREEE_LEVEL_INTERNATIONAL = "I";

export const REFREEE_LEVELS = [{value:REFREEE_LEVEL_STATE, label:"State"}, {value:REFREEE_LEVEL_NATIONAL, label:"National"}, {value:REFREEE_LEVEL_INTERNATIONAL, label:"International"}];