import React from 'react'
import { Layout } from '../layouts/dashboard/layout'
import { Box, Button, Container, Stack, Typography} from '@mui/material';
import { getUserInfo } from '../guards/auth-guard';
import { Link, useSearchParams } from 'react-router-dom';
import { RegistrationForm1 } from '../sections/registrations/RegistrationForm1';
import { RegistrationForm2 } from '../sections/registrations/RegistrationForm2';

const RegistrationsForm = () => {

  const user = getUserInfo();

  const [searchParams] = useSearchParams();
  const formtype = searchParams.get("formtype");

  const ActionButton = () => {

    if(formtype === "2") {
      return(
        <>
          <Button
            component={Link}
            to={-1}
            sx={{ mt: 3 }}
            variant="outlined"
          >
            Back
          </Button>
        </>
      );
    }
    else {
      return <></>;
    }
  }

  return (
    <Layout user = {user} >
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 1
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={4}
            >
              <Stack spacing={1} sx={{width:"100%"}} >
                <Typography variant="h5">
                  Registration Form
                </Typography>
                <Stack
                  alignItems="center"
                  direction="row"
                  spacing={1}
                  justifyContent={"left"}
                  sx={{pt:3, overflowX:"auto"}}
                >
                  <ActionButton />
                </Stack>
              </Stack>
            </Stack>
            {
              (formtype === "2")?
              <RegistrationForm2 />
              :
              <RegistrationForm1 />
            } 
            
          </Stack>
        </Container>
      </Box>
    </Layout>
  )
}

export default RegistrationsForm