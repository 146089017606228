import { Box, Button, Card, CardContent, CardHeader, Unstable_Grid2 as Grid, TextField } from '@mui/material'
import React, { useCallback, useRef, useState } from 'react'
import { ACCEPT_FILE_TYPE, ERROR_STR, FILE_MAX_SIZE, HELPER_TEXT, NEWSFEED_FORM_KEY, RULE_PATTERN_VALUE, snackbarProps } from '../../constant';
import Backdrop from '../../components/Backdrop';
import Snackbar from '../../components/Snackbar';
import { API_STFI } from '../../api/stfi';

const NewsFeedAdd = (props) => {

  const formRef = useRef(null);

  const [values, setValues] = useState({});

  const [backdrop, setBackdrop] = useState(false);
  
  const [snackbar, setSnackbar] = useState(snackbarProps.init());
  snackbar.hide = () => {
    setSnackbar(snackbarProps.hide());
  };

  const [errors] = useState({});

  const handleChange = useCallback(
    (event) => {

      const { name, value } = event.target;

      setValues((prevState) => ({
        ...prevState,
        [name]: value
      }));
    },
    [setValues]
  );

  const submitData = async (formData) => {
    try {
  
      // Authenticate the google token
      const response = await API_STFI.NEWS_FEED_ADD(formData);
      console.log(response);

      // Hide loader
      setBackdrop(false);

      setSnackbar(snackbarProps.success("Form submitted successfully"));
      formRef.current.reset();
      setValues({});

    } catch (error) {

      // Hide loader
     
      if (error.response) {

        console.log(error.response.data.message);
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        
        /*
        if(error.response.status === 401) {

          let newWindow = window.open('/relogin?gmail='+user.email, '_blank', 'width=400,height=300');

          window.addEventListener('message', function(event) {
            newWindow.close();
            console.log('Message received in main window:', event.data);
            submitData(formData);
          });
          
          return;
        }
        */

        

        setBackdrop(false);
        console.error('Server responded with an error:', error.response.status, error.response);
        if(error.response.data?.message) setSnackbar(snackbarProps.error(error.response.data.message));
        else setSnackbar(snackbarProps.error(error.response.statusText));
      } else if (error.request) {
        // The request was made but no response was received
        console.error('No response received from the server');
        setBackdrop(false);
        setSnackbar(snackbarProps.error(ERROR_STR.NO_RESPONSE));
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error setting up the request:', error.message);
        setBackdrop(false);
        setSnackbar(snackbarProps.error(ERROR_STR.STD));
      }
    }
  };

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      const formData = new FormData();
			const formElements = event.target.elements;

			for (let i = 0; i < formElements.length; i++) {
				const element = formElements[i];
				if (element.type === 'file') {
          if(element.files.length) {
            if(element.files[0].size >= FILE_MAX_SIZE ) {
              setSnackbar(snackbarProps.error(`'${element.files[0].name}' file size is more than 1 MB`));
              return;
            }
            formData.append(element.name, element.files[0]);
          }
          // else skip
				}
				else if (element.name) {
					formData.append(element.name, element.value);
				}
			}

      formData.append("id", Math.floor(Math.random() * (100 - 1) + 1));

      setBackdrop(true);

      submitData(formData);
    },
    []
  );

  return (
    <form
      onSubmit={handleSubmit}
      ref={formRef}
    >
      <Card sx = {{height:"100%"}}>
        <CardHeader
          subheader = {"Enter the details to add a new update"}
          title = {"Add Updates"}
        />
        <CardContent sx={{ pt: 0 }}>
            <Box sx={{ m: -1.5 }}>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label={"Heading"}
                        name={NEWSFEED_FORM_KEY.HEADING}
                        required
                        onChange={handleChange}
                        value={values[NEWSFEED_FORM_KEY.HEADING]}
                        error={errors[NEWSFEED_FORM_KEY.HEADING]}
                        helperText={HELPER_TEXT.MAX_64}
                        inputProps={{ maxLength: 64 }}
                      />
                  </Grid>
                  <Grid
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label={"Content"}
                        name={NEWSFEED_FORM_KEY.CONTENT}
                        required
                        onChange={handleChange}
                        value={values[NEWSFEED_FORM_KEY.CONTENT]}
                        error={errors[NEWSFEED_FORM_KEY.CONTENT]}
                        helperText={HELPER_TEXT.MAX_256}
                        inputProps={{ maxLength: 256 }}
                      />
                  </Grid>
                  <Grid
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label={"Date"}
                        name={NEWSFEED_FORM_KEY.Date}
                        required
                        onChange={handleChange}
                        value={values[NEWSFEED_FORM_KEY.Date]}
                        error={errors[NEWSFEED_FORM_KEY.Date]}
                        helperText={HELPER_TEXT.DATE }
                        inputProps={{ pattern: RULE_PATTERN_VALUE.DD_MM_YYYY }}
                      />
                  </Grid>
                  <Grid
                    xs={12}
                  >
                      <TextField
                      fullWidth
                      type="file"
                      label= {"Image"}
                      name= {NEWSFEED_FORM_KEY.IMAGE}
                      error={errors[NEWSFEED_FORM_KEY.IMAGE]}
                      helperText={HELPER_TEXT.IMAGE}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ accept: ACCEPT_FILE_TYPE.IMAGE }}
                      required
                      onChange={handleChange}
                      />
                  </Grid>
                  <Grid
                    xs={12}
                  >
                      <TextField
                      fullWidth
                      type="file"
                      label= {"File"}
                      name= {NEWSFEED_FORM_KEY.FILE}
                      error={errors[NEWSFEED_FORM_KEY.FILE]}
                      helperText={HELPER_TEXT.ONLY_PDF}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ accept: ACCEPT_FILE_TYPE.PDF }}
                      onChange={handleChange}
                      />
                  </Grid>
                </Grid>
            </Box>
        </CardContent>
    </Card>
      <Box sx={{ mt: 5, textAlign: "center" }}>
				<Button type='submit' variant='contained'>Proceed</Button>
			</Box>
      <Snackbar {...snackbar} />
      <Backdrop open = {backdrop}/>
    </form>
  )
}

export default NewsFeedAdd